import './oddbutton.scss'
import React, { useEffect, useState } from 'react';
import isNumber from '../../helpers/isNumber.helper'
import { getFormattedNumberWithComma } from "../../helpers/formatted-number.helper";
import { connectOddbutton } from './oddbutton.connect'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper';

const OddButton = connectOddbutton()(({
  market,
  auth,
  oddData,
  balanceSettings,
  isBack,
  betPlacing,
  oneClick,
  actions,
  showBlock = true,
  showEmpty = false,
  centered = false,
  mainBlock = true,
  isFancy = false,
  marketNum,
  setClicked,
  clicked,
  sportLimits
}) => {
    const [odd, setOdd] = useState(null)
    const [isChanged, setIsChanged] = useState(false)
    const [color, setColor] = useState("")
    const getBtnActive = () => {
        let marketId = market && market.market_id
        let runnerId = oddData && oddData.runner && oddData.runner.id
        const isNotActive = isBtnNotActive() ? 'not-active' : ''
        const active = isFancy ?
            !isNotActive 
            && betPlacing.market === (market && market.fancy_id) 
            && betPlacing.runner === (oddData.size) 
            && betPlacing.isBack === isBack && marketNum === clicked ? 'active' : ''
            :
            !isNotActive 
            && betPlacing.market === marketId
            && betPlacing.runner === runnerId
            && betPlacing.isBack === isBack 
            && marketNum === clicked  ? 'active' : ''

        return  ` ${isNotActive}  ${active} `
    }


    useEffect(() => {
        const isNotActive = isBtnNotActive() ? 'not-active' : ''
        let marketId = market && market.market_id
        let runnerId = oddData && oddData.runner && oddData.runner.id
        const active = isFancy ?
        !isNotActive 
        && betPlacing.market === (market && market.fancy_id) 
        && betPlacing.runner === (oddData.size) 
        && betPlacing.isBack === isBack && marketNum === clicked ? 'active' : ''
        :
        !isNotActive 
        && betPlacing.market === marketId
        && betPlacing.runner === runnerId
        && betPlacing.isBack === isBack 
        && marketNum === clicked  ? 'active' : ''
        if (active && betPlacing.priceActual !== (oddData && oddData.price)) {
            actions.setPriceActual(oddData && oddData.price)
        }
        
    }, [betPlacing?.priceActual, oddData?.price])

    const isBtnNotActive = () => {
        if(isFancy) {
            return !market?.is_active || !isNumber(+odd?.price);
        } else {
            return !market?.status || !isNumber(+odd?.price);
        }
    }

    const getOddPrice = (oddData = odd) => {
         const price = oddData && oddData.price;
        return price
    }

    const getPriseFor_SB = (marketId) => {
        return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((odd?.price - 1) * 100).toFixed(2).replace('.00', '') : odd?.price);
    }

    const getOddVolume = () => {
        const size = odd && odd.size;
        const volumeMultiplier = (market && balanceSettings && balanceSettings.settings && balanceSettings.settings[market.eventTypeId] && balanceSettings.settings[market.eventTypeId].volumeMultiplier) || 1;
        // if (!size || !volumeMultiplier || market.mtype === 'MATCH_ODDS_SB') {
        //     return;
        // }
        if (!size ) {
            return;
        }
        if(market?.id?.includes('_BR')) {
            // const resultVolume = Math.ceil(size * volumeMultiplier);
            // return <div className="odd-button__volume">{resultVolume >= 500000 ? '500.000+' : getFormattedNumberWithComma(resultVolume)}</div>
            return null
        }
        if (market?.mtype && market?.mtype === 'MATCH_ODDS_SB') {
            return;
        }
        const resultVolume = Math.ceil(size * volumeMultiplier);
        return <div className="odd-button__volume">{resultVolume >= 500000 ? '500.000+' : getCurrencyFormat(resultVolume)}</div>
    }

    const select = () => {
        let marketId;
        let runnerId;
        let limits;
        if(isFancy) {
            marketId = market && market.fancy_id
            runnerId = market && market.selection_id
            limits = sportLimits && sportLimits.data && sportLimits.data?.session && sportLimits.data?.session[marketId]
        } else {
            marketId = market && market.market_id
            runnerId = (oddData && oddData.runner && oddData.runner.id) || oddData.selectionId
            limits = sportLimits && sportLimits?.data && sportLimits.data[marketId]
        }
        if (!marketId || !runnerId) return
        actions.setMarket(marketId)
        actions.setRunner(isFancy ? oddData.size : runnerId)
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
        actions.setEventName(market && market.market_name)
        actions.setIsBack(isBack)
        actions.setPrice(oddData && oddData.price)
        actions.setPriceActual(oddData && oddData.price)
        actions.setDelay(market && market.betDelay)
        actions.setMtype(market && market.mtype)
        actions.setBtype(market && market.btype)
        actions.setLine(isFancy)
        actions.setStartTime(market && market?.match_date)
        actions.setEventTypeId(market && market.eventTypeId)
        //TODO: add market limits betslip
        actions.setMaxBet(limits && limits.market_max_stack || limits && limits.session_max_stack) 
        actions.setMinBet(limits && limits.market_min_stack || limits && limits.session_min_stack)
        //   setMinMaxAll
        if (oneClick.active) {
            actions.setStake(oneClick.settings[oneClick.selected - 1])
            actions.setBetPlacingPopoverStage(false)
            actions.setShowBetPlacingPopover(true)
            actions.setFromOneClick(true)
        } else {
            actions.setFromOneClick(false)
            actions.setStake(null)
        }
        if(auth) {
            actions.requestBetPlacingStart(marketId, runnerId)
                .then((response) => {

                })
                .catch((err) => console.log('err = ', err))
            }
        }

    useEffect(() => {
        if (isDataChanged()) {
            setIsChanged(true);
            setTimeout(() => {
                setIsChanged(false);
            }, 300)
            setOdd(oddData)
        }
    }, [oddData?.price, oddData?.size])

    useEffect(() => {
         setColor(isBack ? `back-color` : `lay-color`)
    }, [isBack])

    const isDataChanged = () => {
        return getOddPrice() !== getOddPrice(oddData) || odd?.size !== oddData?.size
    }


    return (
        <span className={`odd-button ${color} ${getBtnActive()} ${isChanged ? 'change-color' : ''} ${mainBlock ? "" : "secondary"}`}
            onClick={() => {
                if (!isBtnNotActive() && (oddData && oddData.price > 1)) {
                setClicked && setClicked(marketNum)
                console.log("click select")
                select()
                }
            }}
        >
            <span className={`odd-button__inner ${centered ? 'odd-button__inner--centered' : ''} ${isFancy && oddData.price == 0 ? "hide-zero": ""}`}>
                {isFancy && isNumber(+odd?.price) && oddData && <div className={`odd-button__price`}> {oddData.line}</div>}
                <div className={`${isFancy ? "odd-button__volume": "odd-button__price"}`}>
                    {isNumber(+odd?.price) && odd?.price === 0 
                        ? ' ' 
                        : isNumber(+odd?.price)
                        ? getCurrencyFormat(getPriseFor_SB(market && market.id)) 
                        : (showEmpty ? '' : '-')}
                </div>
                {isNumber(+odd?.price) && odd?.price === 0 ? ' ' : isNumber(+odd?.price) ? getOddVolume() : ''}
            </span>
        </span>
    )
})

export default OddButton
