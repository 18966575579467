import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestChangePassword } from '../../store/user/user.thunks'
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';


export const connectFooterDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      version: state.version,
      inplayPopular: state.inplayPopular,
      B2C: state.B2C,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setIsNavigationOpen,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
