import axios from 'axios'
import { BASE_DEV_URL, BASE_DEV_URL_PUBLIC } from '../config/api.config'
// import { BASE_PROD_URL } from '../config/api.config'
import { ID_TOKEN, USER_DATA, TOKEN_EXP } from '../config/app.config'

//const BASE_URL = (window.location.host == "localhost:3000" || window.location.host == "d1xzve0qf943ll.cloudfront.net") ? BASE_DEV_URL : `${window.location.origin}/api`
// const BASE_URL = BASE_PROD_URL

const BASE_URL =  BASE_DEV_URL
const httpAuth = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
  },
})

const clearToken = () => {

  setTimeout(() => {
    window.location.reload()
  }, 3000)

  window.onunload = function() {
    localStorage.removeItem(ID_TOKEN)
    localStorage.removeItem(USER_DATA)
    localStorage.removeItem(TOKEN_EXP)
    localStorage.setItem("isLogin", false );
  };
};

httpAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(`${ID_TOKEN}`)
    const token_exp = localStorage.getItem(TOKEN_EXP);
    const isLogin = localStorage.getItem("isLogin");
    if (!token && isLogin === "true") {
      console.log("clear1")
      clearToken()
      throw new Error("Response has no token");
      return
    }

    if (token_exp &&  new Date((token_exp - 5) * 1000 ) < new Date()) {
      clearToken()
    }
    // console.log("added config in API AUTH")
    config.headers.authorization = `Bearer ${token}`
    
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
httpAuth.interceptors.response.use(
  (response) => {

    return response
  },
  async function (error) {
    if (error.response?.status === 403 || error.response?.status === 401 || error.response?.logout|| error?.logout) {
      const localToken = localStorage.getItem(ID_TOKEN)


      // if (localToken) {
      //   clearToken()
      // }
      if (localToken)
        setTimeout(() => {
          window.location.reload()
        }, 3000)

        console.log("clear2")


      localStorage.removeItem(ID_TOKEN)
      localStorage.removeItem(USER_DATA)
      localStorage.removeItem(TOKEN_EXP)
      localStorage.setItem("isLogin", false );
    }

    return Promise.reject(error)
  }
)

export default httpAuth
