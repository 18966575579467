import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setConfirmation, setMarket, setRunner, setIsBack, setPrice, setPriceActual, setStake, setDelay, setShowBetPlacingPopover, setBetPlacingPopoverStage } from '../../store/betPlacing/betPlacing.slice'
import { requestSetOrder,requestSetFancyOrder } from '../../store/betPlacing/betPlacing.thunks'
import {requestOpenBets} from '../../store/openBets/openBets.thunks'
import { requestStack } from '../../store/auth/auth.thunks'

export const connectDesktopBetPlacing = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      betPlacing: state.betPlacing,
      presetStakeSettings: state.user.settings.presetStakeSettings,
      oneClick: state.user.settings.oneClickSettings.active,
      balance: state.balance,
      sportData: state.sportEvent.sportData,
      sportPageEvent: state.sportPageEvent,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSetOrder,
      requestSetFancyOrder,
      setConfirmation,
      setMarket,
      setRunner,
      setIsBack,
      setPrice,
      setPriceActual,
      setDelay,
      setStake,
      setShowBetPlacingPopover,
      setBetPlacingPopoverStage,
      requestOpenBets,
      requestStack,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
