import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestTwitterSettings } from '../../store/twitterSettings/twitterSettings.thunks';
import { requestSportStart } from '../../store/eventPage/eventPage.thunks';
import { requestOpenBets } from '../../store/openBets/openBets.thunks';
import { requestSportData, requestSportDataFancy, requestSportStats, requestSportVideo, requestSportLimits } from '../../store/sportEvent/sportEvent.thunks';
import { getSportEventError, getSportStatsError, getSportVisualisationError, getSportVideoError } from '../../store/sportEvent/sportEvent.slice';
import { setRulesPopUp } from '../../store/rulesPopUp/rulesPopUp.slice';

export const connectEventPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      lastActivityDate: state.balance.lastActivityDate,
      sportEventStats: state.sportEvent.sportStats,
      twitterSettings: state.twitterSettings,
      openBets: state.openBets,
      orders: state.eventPage.orders,
      sportData: state.sportEvent.sportData,
      sportVideo: state.sportEvent.sportVideo,
      sportLimits: state.sportEvent.sportLimits,
      sportDataFancy: state.sportEvent.fancyData,
      rulesPopUp: state.rulesPopUp,
      auth: state.auth,
      elementsParams: state.elementsParams
      // raceOdds: state.raceOdds,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestTwitterSettings,
      requestOpenBets,
      requestSportStart,
      requestSportData,
      requestSportDataFancy,
      requestSportStats,
      requestSportVideo,
      getSportEventError,
      getSportStatsError,
      getSportVideoError,
      getSportVisualisationError,
      setRulesPopUp,
      requestSportLimits
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
