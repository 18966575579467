import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import { getMenuError, getMenuSuccess } from './menu.slice'


export const requestMenu = (data) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  if(checkAuth) {
    if(data?.sport_id === "7" || data?.sport_id === "4339") {
      console.log("its racing")
      try {
        const response = await httpAuth.post(`/market/allRacingMarkets`, data)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          console.log("result for racing", res)
          dispatch(getMenuSuccess(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        dispatch(getMenuError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
    } else {
      try {
        const response = await httpAuth.post(`/series/series`, data)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          dispatch(getMenuSuccess(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        dispatch(getMenuError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
    }
  } else {
    if(data?.sport_id === "7" || data?.sport_id === "4339") {
      console.log("its racing")
      try {
        const response = await httpNotAuth.post(`/market/allRacingMarketsOpen`, data)
  
        if (response?.data?.status) {
          console.log("result for racing", response)
          let res = response?.data?.data
          dispatch(getMenuSuccess(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        dispatch(getMenuError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
    } else {
    try {
      const response = await httpNotAuth.post(`/series/series`, data)
      if (response?.data?.status) {
        let res = response?.data?.data
        dispatch(getMenuSuccess(res))
        // const menu = await response.data.result.filter((item, index, self) =>
        //   index === self.findIndex((t) => t.id === item.id)
        // )
      }
      return response
    } catch (e) {
      dispatch(getMenuError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }}
}
