import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import { setSearchResults, resetSearchResults, setlist } from './search.slice'

export const requestSearchResults = (value) => async (dispatch, getState) => {
  try {
    if (value.length > 2) {
        const result = [];
        const menu = getState().menu;
        if (menu)
        menu.forEach(menuItem => {
            menuItem.children
                .forEach(eventGroup => {
                    eventGroup.children
                    .filter(event => event.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1 && result.push(event))
                })
        })
        dispatch(setSearchResults(undefined))
        dispatch(setSearchResults(result))
        return true
    }

  } catch (e) {
    dispatch(resetSearchResults())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}

export const requestSearchList = () => async (dispatch, getState) => {
  const checkAuth = getState().auth
  if(checkAuth) {
      try {
        const response = await httpAuth.post(`/match/getMatches`)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          console.log("search res", res)
          dispatch(setlist(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        // dispatch(getMenuTopError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
    
  } else {
      try {
        const response = await httpNotAuth.post(`/match/matches`)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          console.log("search res", res)
          dispatch(setlist(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        // dispatch(getMenuTopError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
  }
}
