export // Full static list of countries with codes and flags
const countryOptions = [
  { value: '+93', label: 'Afghanistan (+93)', flag: 'af' },
  { value: '+355', label: 'Albania (+355)', flag: 'al' },
  { value: '+213', label: 'Algeria (+213)', flag: 'dz' },
  { value: '+376', label: 'Andorra (+376)', flag: 'ad' },
  { value: '+244', label: 'Angola (+244)', flag: 'ao' },
  { value: '+1-268', label: 'Antigua and Barbuda (+1-268)', flag: 'ag' },
  { value: '+54', label: 'Argentina (+54)', flag: 'ar' },
  { value: '+374', label: 'Armenia (+374)', flag: 'am' },
  { value: '+61', label: 'Australia (+61)', flag: 'au' },
  { value: '+43', label: 'Austria (+43)', flag: 'at' },
  { value: '+994', label: 'Azerbaijan (+994)', flag: 'az' },
  { value: '+1-242', label: 'Bahamas (+1-242)', flag: 'bs' },
  { value: '+973', label: 'Bahrain (+973)', flag: 'bh' },
  { value: '+880', label: 'Bangladesh (+880)', flag: 'bd' },
  { value: '+1-246', label: 'Barbados (+1-246)', flag: 'bb' },
  { value: '+375', label: 'Belarus (+375)', flag: 'by' },
  { value: '+32', label: 'Belgium (+32)', flag: 'be' },
  { value: '+501', label: 'Belize (+501)', flag: 'bz' },
  { value: '+229', label: 'Benin (+229)', flag: 'bj' },
  { value: '+975', label: 'Bhutan (+975)', flag: 'bt' },
  { value: '+591', label: 'Bolivia (+591)', flag: 'bo' },
  { value: '+387', label: 'Bosnia and Herzegovina (+387)', flag: 'ba' },
  { value: '+267', label: 'Botswana (+267)', flag: 'bw' },
  { value: '+55', label: 'Brazil (+55)', flag: 'br' },
  { value: '+673', label: 'Brunei (+673)', flag: 'bn' },
  { value: '+359', label: 'Bulgaria (+359)', flag: 'bg' },
  { value: '+226', label: 'Burkina Faso (+226)', flag: 'bf' },
  { value: '+257', label: 'Burundi (+257)', flag: 'bi' },
  { value: '+238', label: 'Cabo Verde (+238)', flag: 'cv' },
  { value: '+855', label: 'Cambodia (+855)', flag: 'kh' },
  { value: '+237', label: 'Cameroon (+237)', flag: 'cm' },
  { value: '+1', label: 'Canada (+1)', flag: 'ca' },
  { value: '+236', label: 'Central African Republic (+236)', flag: 'cf' },
  { value: '+235', label: 'Chad (+235)', flag: 'td' },
  { value: '+56', label: 'Chile (+56)', flag: 'cl' },
  { value: '+86', label: 'China (+86)', flag: 'cn' },
  { value: '+57', label: 'Colombia (+57)', flag: 'co' },
  { value: '+269', label: 'Comoros (+269)', flag: 'km' },
  { value: '+242', label: 'Congo (+242)', flag: 'cg' },
  { value: '+243', label: 'Congo (Democratic Republic of the) (+243)', flag: 'cd' },
  { value: '+506', label: 'Costa Rica (+506)', flag: 'cr' },
  { value: '+385', label: 'Croatia (+385)', flag: 'hr' },
  { value: '+53', label: 'Cuba (+53)', flag: 'cu' },
  { value: '+357', label: 'Cyprus (+357)', flag: 'cy' },
  { value: '+420', label: 'Czech Republic (+420)', flag: 'cz' },
  { value: '+45', label: 'Denmark (+45)', flag: 'dk' },
  { value: '+253', label: 'Djibouti (+253)', flag: 'dj' },
  { value: '+1-767', label: 'Dominica (+1-767)', flag: 'dm' },
  { value: '+1-809', label: 'Dominican Republic (+1-809)', flag: 'do' },
  { value: '+593', label: 'Ecuador (+593)', flag: 'ec' },
  { value: '+20', label: 'Egypt (+20)', flag: 'eg' },
  { value: '+503', label: 'El Salvador (+503)', flag: 'sv' },
  { value: '+240', label: 'Equatorial Guinea (+240)', flag: 'gq' },
  { value: '+291', label: 'Eritrea (+291)', flag: 'er' },
  { value: '+372', label: 'Estonia (+372)', flag: 'ee' },
  { value: '+268', label: 'Eswatini (+268)', flag: 'sz' },
  { value: '+251', label: 'Ethiopia (+251)', flag: 'et' },
  { value: '+679', label: 'Fiji (+679)', flag: 'fj' },
  { value: '+358', label: 'Finland (+358)', flag: 'fi' },
  { value: '+33', label: 'France (+33)', flag: 'fr' },
  { value: '+241', label: 'Gabon (+241)', flag: 'ga' },
  { value: '+220', label: 'Gambia (+220)', flag: 'gm' },
  { value: '+995', label: 'Georgia (+995)', flag: 'ge' },
  { value: '+49', label: 'Germany (+49)', flag: 'de' },
  { value: '+233', label: 'Ghana (+233)', flag: 'gh' },
  { value: '+30', label: 'Greece (+30)', flag: 'gr' },
  { value: '+1-473', label: 'Grenada (+1-473)', flag: 'gd' },
  { value: '+502', label: 'Guatemala (+502)', flag: 'gt' },
  { value: '+224', label: 'Guinea (+224)', flag: 'gn' },
  { value: '+245', label: 'Guinea-Bissau (+245)', flag: 'gw' },
  { value: '+592', label: 'Guyana (+592)', flag: 'gy' },
  { value: '+509', label: 'Haiti (+509)', flag: 'ht' },
  { value: '+504', label: 'Honduras (+504)', flag: 'hn' },
  { value: '+36', label: 'Hungary (+36)', flag: 'hu' },
  { value: '+354', label: 'Iceland (+354)', flag: 'is' },
  { value: '+91', label: 'India (+91)', flag: 'in' },
  { value: '+62', label: 'Indonesia (+62)', flag: 'id' },
  { value: '+98', label: 'Iran (+98)', flag: 'ir' },
  { value: '+964', label: 'Iraq (+964)', flag: 'iq' },
  { value: '+353', label: 'Ireland (+353)', flag: 'ie' },
  { value: '+972', label: 'Israel (+972)', flag: 'il' },
  { value: '+39', label: 'Italy (+39)', flag: 'it' },
  { value: '+1-876', label: 'Jamaica (+1-876)', flag: 'jm' },
  { value: '+81', label: 'Japan (+81)', flag: 'jp' },
  { value: '+962', label: 'Jordan (+962)', flag: 'jo' },
  { value: '+7', label: 'Kazakhstan (+7)', flag: 'kz' },
  { value: '+254', label: 'Kenya (+254)', flag: 'ke' },
  { value: '+686', label: 'Kiribati (+686)', flag: 'ki' },
  { value: '+850', label: 'Korea (North) (+850)', flag: 'kp' },
  { value: '+82', label: 'Korea (South) (+82)', flag: 'kr' },
  { value: '+383', label: 'Kosovo (+383)', flag: 'xk' },
  { value: '+965', label: 'Kuwait (+965)', flag: 'kw' },
  { value: '+996', label: 'Kyrgyzstan (+996)', flag: 'kg' },
  { value: '+856', label: 'Laos (+856)', flag: 'la' },
  { value: '+371', label: 'Latvia (+371)', flag: 'lv' },
  { value: '+961', label: 'Lebanon (+961)', flag: 'lb' },
  { value: '+266', label: 'Lesotho (+266)', flag: 'ls' },
  { value: '+231', label: 'Liberia (+231)', flag: 'lr' },
  { value: '+218', label: 'Libya (+218)', flag: 'ly' },
  { value: '+423', label: 'Liechtenstein (+423)', flag: 'li' },
  { value: '+370', label: 'Lithuania (+370)', flag: 'lt' },
  { value: '+352', label: 'Luxembourg (+352)', flag: 'lu' },
  { value: '+261', label: 'Madagascar (+261)', flag: 'mg' },
  { value: '+265', label: 'Malawi (+265)', flag: 'mw' },
  { value: '+60', label: 'Malaysia (+60)', flag: 'my' },
  { value: '+960', label: 'Maldives (+960)', flag: 'mv' },
  { value: '+223', label: 'Mali (+223)', flag: 'ml' },
  { value: '+356', label: 'Malta (+356)', flag: 'mt' },
  { value: '+692', label: 'Marshall Islands (+692)', flag: 'mh' },
  { value: '+222', label: 'Mauritania (+222)', flag: 'mr' },
  { value: '+230', label: 'Mauritius (+230)', flag: 'mu' },
  { value: '+52', label: 'Mexico (+52)', flag: 'mx' },
  { value: '+691', label: 'Micronesia (+691)', flag: 'fm' },
  { value: '+373', label: 'Moldova (+373)', flag: 'md' },
  { value: '+377', label: 'Monaco (+377)', flag: 'mc' },
  { value: '+976', label: 'Mongolia (+976)', flag: 'mn' },
  { value: '+382', label: 'Montenegro (+382)', flag: 'me' },
  { value: '+212', label: 'Morocco (+212)', flag: 'ma' },
  { value: '+258', label: 'Mozambique (+258)', flag: 'mz' },
  { value: '+95', label: 'Myanmar (+95)', flag: 'mm' },
  { value: '+264', label: 'Namibia (+264)', flag: 'na' },
  { value: '+674', label: 'Nauru (+674)', flag: 'nr' },
  { value: '+977', label: 'Nepal (+977)', flag: 'np' },
  { value: '+31', label: 'Netherlands (+31)', flag: 'nl' },
  { value: '+64', label: 'New Zealand (+64)', flag: 'nz' },
  { value: '+505', label: 'Nicaragua (+505)', flag: 'ni' },
  { value: '+227', label: 'Niger (+227)', flag: 'ne' },
  { value: '+234', label: 'Nigeria (+234)', flag: 'ng' },
  { value: '+389', label: 'North Macedonia (+389)', flag: 'mk' },
  { value: '+47', label: 'Norway (+47)', flag: 'no' },
  { value: '+968', label: 'Oman (+968)', flag: 'om' },
  { value: '+92', label: 'Pakistan (+92)', flag: 'pk' },
  { value: '+680', label: 'Palau (+680)', flag: 'pw' },
  { value: '+507', label: 'Panama (+507)', flag: 'pa' },
  { value: '+675', label: 'Papua New Guinea (+675)', flag: 'pg' },
  { value: '+595', label: 'Paraguay (+595)', flag: 'py' },
  { value: '+51', label: 'Peru (+51)', flag: 'pe' },
  { value: '+63', label: 'Philippines (+63)', flag: 'ph' },
  { value: '+48', label: 'Poland (+48)', flag: 'pl' },
  { value: '+351', label: 'Portugal (+351)', flag: 'pt' },
  { value: '+974', label: 'Qatar (+974)', flag: 'qa' },
  { value: '+40', label: 'Romania (+40)', flag: 'ro' },
  { value: '+7', label: 'Russia (+7)', flag: 'ru' },
  { value: '+250', label: 'Rwanda (+250)', flag: 'rw' },
  { value: '+1-869', label: 'Saint Kitts and Nevis (+1-869)', flag: 'kn' },
  { value: '+1-758', label: 'Saint Lucia (+1-758)', flag: 'lc' },
  { value: '+1-784', label: 'Saint Vincent and the Grenadines (+1-784)', flag: 'vc' },
  { value: '+685', label: 'Samoa (+685)', flag: 'ws' },
  { value: '+378', label: 'San Marino (+378)', flag: 'sm' },
  { value: '+239', label: 'Sao Tome and Principe (+239)', flag: 'st' },
  { value: '+966', label: 'Saudi Arabia (+966)', flag: 'sa' },
  { value: '+221', label: 'Senegal (+221)', flag: 'sn' },
  { value: '+381', label: 'Serbia (+381)', flag: 'rs' },
  { value: '+248', label: 'Seychelles (+248)', flag: 'sc' },
  { value: '+232', label: 'Sierra Leone (+232)', flag: 'sl' },
  { value: '+65', label: 'Singapore (+65)', flag: 'sg' },
  { value: '+421', label: 'Slovakia (+421)', flag: 'sk' },
  { value: '+386', label: 'Slovenia (+386)', flag: 'si' },
  { value: '+677', label: 'Solomon Islands (+677)', flag: 'sb' },
  { value: '+252', label: 'Somalia (+252)', flag: 'so' },
  { value: '+27', label: 'South Africa (+27)', flag: 'za' },
  { value: '+211', label: 'South Sudan (+211)', flag: 'ss' },
  { value: '+34', label: 'Spain (+34)', flag: 'es' },
  { value: '+94', label: 'Sri Lanka (+94)', flag: 'lk' },
  { value: '+249', label: 'Sudan (+249)', flag: 'sd' },
  { value: '+597', label: 'Suriname (+597)', flag: 'sr' },
  { value: '+46', label: 'Sweden (+46)', flag: 'se' },
  { value: '+41', label: 'Switzerland (+41)', flag: 'ch' },
  { value: '+963', label: 'Syria (+963)', flag: 'sy' },
  { value: '+886', label: 'Taiwan (+886)', flag: 'tw' },
  { value: '+992', label: 'Tajikistan (+992)', flag: 'tj' },
  { value: '+255', label: 'Tanzania (+255)', flag: 'tz' },
  { value: '+66', label: 'Thailand (+66)', flag: 'th' },
  { value: '+670', label: 'Timor-Leste (+670)', flag: 'tl' },
  { value: '+228', label: 'Togo (+228)', flag: 'tg' },
  { value: '+676', label: 'Tonga (+676)', flag: 'to' },
  { value: '+1-868', label: 'Trinidad and Tobago (+1-868)', flag: 'tt' },
  { value: '+216', label: 'Tunisia (+216)', flag: 'tn' },
  { value: '+90', label: 'Turkey (+90)', flag: 'tr' },
  { value: '+993', label: 'Turkmenistan (+993)', flag: 'tm' },
  { value: '+688', label: 'Tuvalu (+688)', flag: 'tv' },
  { value: '+256', label: 'Uganda (+256)', flag: 'ug' },
  { value: '+380', label: 'Ukraine (+380)', flag: 'ua' },
  { value: '+971', label: 'United Arab Emirates (+971)', flag: 'ae' },
  { value: '+44', label: 'United Kingdom (+44)', flag: 'gb' },
  { value: '+1', label: 'United States (+1)', flag: 'us' },
  { value: '+598', label: 'Uruguay (+598)', flag: 'uy' },
  { value: '+998', label: 'Uzbekistan (+998)', flag: 'uz' },
  { value: '+678', label: 'Vanuatu (+678)', flag: 'vu' },
  { value: '+58', label: 'Venezuela (+58)', flag: 've' },
  { value: '+84', label: 'Vietnam (+84)', flag: 'vn' },
  { value: '+681', label: 'Wallis and Futuna (+681)', flag: 'wf' },
  { value: '+967', label: 'Yemen (+967)', flag: 'ye' },
  { value: '+260', label: 'Zambia (+260)', flag: 'zm' },
  { value: '+263', label: 'Zimbabwe (+263)', flag: 'zw' }
];
