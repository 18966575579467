import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getLogoError, getLogoSuccess } from './logo.slice'
import { BASE_DEV_URL } from '../../config/api.config'


export const requestLogo = (data) => async (dispatch, getState) => {
    try {
      const response = await httpNotAuth.post(`/content/getLogo`, data)
      if (response?.data?.status) {
        if (response?.data?.data.hasOwnProperty('self_host')) {
          if (response?.data?.data?.self_host) {
            const logoUrl = BASE_DEV_URL + response?.data?.data?.description;
            dispatch(getLogoSuccess(logoUrl))
            localStorage.setItem('logo', logoUrl);
          } else {
            dispatch(getLogoSuccess(response?.data?.data?.description))
            localStorage.setItem('logo', response?.data?.data?.description);
          }
        } else {
          const logoUrl = BASE_DEV_URL + response?.data?.data?.description;
          dispatch(getLogoSuccess(logoUrl))
          localStorage.setItem('logo', logoUrl);
        }
        // const menu = await response.data.result.filter((item, index, self) =>
        //   index === self.findIndex((t) => t.id === item.id)
        // )
      }
      return response
    } catch (e) {
      dispatch(getLogoError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
}
