import './BonusCardDetailes.scss'
import React, { useState } from 'react'
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const BonusCardDetailes = ({promo, onDidDismiss }) => {

const [activeLang, setActiveLang] = useState('EN');

  function createMarkup() {
    if (promo) {
      return { __html: promo.replaceAll('\\n', '') }
    }
  }

  return createPortal(
    <>
      {/* {promo ? ( */}
        <div className="bonusCardDetailes">
          <div className="bonusCardDetailes-main">
            <div className='bonusCardDetailes-main_header'>

            <div className='bonusCardDetailes-main_header_title'>Bonus Terms & Conditions</div>
            </div>
          <div className='bonusCardDetailes_languageCont'>
            <div
            onClick={() => setActiveLang('EN')} 
            className={`bonusCardDetailes_languageCont-item ${activeLang === 'EN' ? 'active' : ''}`}>English</div>
            <div 
            onClick={() => setActiveLang('HI')} 
            className={`bonusCardDetailes_languageCont-item ${activeLang === 'HI' ? 'active' : ''}`}>Hindi</div>
            
          </div>
            <div onClick={() => onDidDismiss()} className="bonusCardDetailes-main-close"></div>
            <div className="bonusCardDetailes-main-content">
              {/*<div className='bonusCardDetailes-main-img'>*/}
              {/*  <img src={promoBonus} alt='promoBonus'></img>*/}
              {/*</div>*/}
              <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
          </div>
        </div>
      {/* ) : <></>} */}
    </>, modalRoot
    )
}

export default BonusCardDetailes
