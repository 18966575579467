export const ExchangeGamesData = [
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/dragontiger.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150001',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/dragontigeroneday.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/20-20poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/onedayteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/bollywoodcasino.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/baccarat.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/sicbo.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/roulette.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/superover.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/fivecricket.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/lucky7.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/andarbahar.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/2020teenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/2cardonedayteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/muflisteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/teenpattitest.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/32cards.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/queenrace.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/worlimatka.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/race20.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/dragontigerlion.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/amarakbaranthony.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/2cardsteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/3cardjudgement.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/trio.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/lottery.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/1-card-20-20.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/1-card-meter.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/note-umber.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/kaun-banega-crorepati.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/raceto2nd.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/trio.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/bollywoodcasino.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/aaa.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/queenrace.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/kingrace.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/worlimatka.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/lucky7.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/dragontigerlion.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/dragontiger.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/muflisteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/teenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/2cardteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/highcard.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
    },
];