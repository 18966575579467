import "./styles/index.scss"
import React, { useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { connectSicbo } from './Sicbo.connect'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { MarketSicbo } from './components/MarketSicbo'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'

import onePointDice from "../../../assets/images/exchangeGames/sicbo/one-point-dice.svg"
import twoPointDice from "../../../assets/images/exchangeGames/sicbo/two-point-dice.svg"
import threePointDice from "../../../assets/images/exchangeGames/sicbo/three-point-dice.svg"
import fourPointDice from "../../../assets/images/exchangeGames/sicbo/four-point-dice.svg"
import fivePointDice from "../../../assets/images/exchangeGames/sicbo/five-point-dice.svg"
import sixPointDice from "../../../assets/images/exchangeGames/sicbo/six-point-dice.svg"


const Sicbo = connectSicbo()(
  ({
     betPlacing,
     actions,
     exchangeGames,
     sportEvent,
     exposure,
     headerHeight,
     auth,
     elementsParams
   }) => {

    // window width
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
    // end window width

    const dataOdd = sportEvent.sportData

    // request Exchange Game data

    useEffect(() => {
      const timer = setIntervalAsync(async() => {
        await actions
              .requestExchangeGames('98566')
              .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)
    }, [actions])
    // End request Exchange Game data

    // Show betslip
    // const renderBetPlacing = () => {
    //   if (betPlacing.runner && width < 1024) {
    //     return <BetPlacing headerHeight={headerHeight}/>
    //   }
    //   return null
    // }
    // End Show betslip

    // Bet placing
    const select = (dataOdd, item, isBack) => {
      const runner = { name: item.name, id: item.id }
      const oddData = isBack
        ? { ...item.back[0], runner }
        : { ...item.lay[0], runner }
      const marketId = dataOdd && dataOdd.id
      const runnerId = oddData && oddData.runner && oddData.runner.id
      console.log(marketId, runnerId)
      if (!marketId || !runnerId) return
      actions.setMarket(marketId)
      actions.setRunner(runnerId)
      actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
      actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
      actions.setIsBack(isBack)
      actions.setPrice(oddData && oddData.price)
      actions.setPriceActual(oddData && oddData.price)
      actions.setDelay(dataOdd && dataOdd.betDelay)
      actions.setMtype(dataOdd && dataOdd.mtype)
      actions.setBtype(dataOdd && dataOdd.btype)
      actions.setLine(oddData && oddData.line)
      actions.setFromOneClick(false)
      actions.setStake(null)

      actions
        .requestBetPlacingStart(marketId, runnerId)
        .then((response) => {})
        .catch((err) => console.log('err = ', err))
    }

    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
      return (
        dataOdd[marketIndex].isBettable
          ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
          : null
      )
    }
    // End Bet placing

    //get price
    const getMarketPrice = (marketIndex, runnersIndex) => {
      let oddsMarketResult = sportEvent.sportData
      let tempResult = null;
      if (oddsMarketResult &&
        oddsMarketResult[marketIndex] &&
        oddsMarketResult[marketIndex].runners[runnersIndex] &&
        oddsMarketResult[marketIndex].runners[runnersIndex].back[0] &&
        oddsMarketResult[marketIndex].runners[runnersIndex].back[0].price) {

        tempResult = oddsMarketResult &&
          oddsMarketResult[marketIndex] &&
          oddsMarketResult[marketIndex].runners[runnersIndex] &&
          oddsMarketResult[marketIndex].runners[runnersIndex].back[0] &&
          oddsMarketResult[marketIndex].runners[runnersIndex].back[0].price
      }

      if(tempResult > 1 && tempResult < 2) {
        tempResult = oddsMarketResult[marketIndex].runners[runnersIndex].back[0].price.toString()
        const convertedResult = Number(tempResult.replace('1', 0))

        return (
          convertedResult
        )
      }
      else {
        return (
          oddsMarketResult &&
          oddsMarketResult[marketIndex] &&
          oddsMarketResult[marketIndex].runners[runnersIndex] &&
          oddsMarketResult[marketIndex].runners[runnersIndex].back[0] &&
          oddsMarketResult[marketIndex].runners[runnersIndex].back[0].price
        )
      }
    }
    //end get price

    // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
      if(auth) {
        let id = dataOdd[marketIndex].runners[runnerIndex].id
        let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

        return (
          <div
            className={
              objFinder > 0
                ? 'exposure-box-modern exposure-modern--pos'
                : 'exposure-box-modern exposure-modern--neg'
            }
          >
            {objFinder}
            {/*{exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl.toFixed(2)}*/}
          </div>
        )
      } else return
    }
    // end get exposure

    // lock market
    const resultStatus = (marketIndex) => {
      return !dataOdd[marketIndex].isBettable && 'lock-ui'
    }
    // end lock market

    // get winner
    const getWinner = (marketIndex, runnerIndex) => {
      return exchangeGames &&
        exchangeGames[marketIndex] &&
        exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
        exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
    }
    // get winner


    return (
      <div className={`sicbo-main`}>
        <CardsInfo/>
        {/*{*/}
        {/*  width < 1024*/}
        {/*    ?*/}
        {/*    <div className="gc-game-title">*/}
        {/*      <div className="game-title">SUPER SICBO </div>*/}
        {/*      <div className="game-round-id">*/}
        {/*        #{exchangeGames && exchangeGames[0]?.roundId}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    :*/}
        {/*    ""*/}
        {/*}*/}
        <div className="sicbo-table">
          <div className="sicbo-table__body">
            {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
            <div className="sicbo-table__body-column small-odd-column">
              <div className="sicbo-table__body-tr">
                <div className="sicbo-table__body-tr-header">
                  <span className="sicbo-table__body-column-header-text">{getMarketPrice(0,0)}:1 Lose if any tripple</span>
                </div>
                <div className="sicbo-table__body-td-wrapper small-odd-wrapper">
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={1} runnerIndex={0} textContent={"SMALL"} textContentNums={"4 - 10"}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={0} runnerIndex={0} textContent={"ODD"}/>
                </div>
              </div>
            </div>
            <div className="sicbo-table__body-column dice-column">
              <div className="sicbo-table__body-tr first-dice-tr">
                <div className="sicbo-table__body-tr-header">
                  <p className="sicbo-table__body-column-header-text">{getMarketPrice(3,0)}:1</p>
                </div>
                <div className="sicbo-table__body-td-wrapper first-dice-td-wrapper">
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={0} imageDiceType={onePointDice}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={1} imageDiceType={twoPointDice}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={2} imageDiceType={threePointDice}/>
                </div>
              </div>
              <div className="sicbo-table__body-tr any-triple-tr">
                {
                  width >= 1024
                    ?
                    <div className="sicbo-table__body-tr-header">
                      <p className="sicbo-table__body-column-header-text">{getMarketPrice(2,0)}:1</p>
                    </div>
                    : ""
                }
                <div className="sicbo-table__body-td-wrapper">
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={2} runnerIndex={0} textContent={"ANY"} getMarketPrice={getMarketPrice}/>
                </div>
              </div>
              <div className="sicbo-table__body-tr second-dice-tr">
                <div className="sicbo-table__body-tr-header">
                  <p className="sicbo-table__body-column-header-text">{getMarketPrice(3,0)}:1</p>
                </div>
                <div className="sicbo-table__body-td-wrapper second-dice-td-wrapper">
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={3} imageDiceType={fourPointDice}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={4} imageDiceType={fivePointDice}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={3} runnerIndex={5} imageDiceType={sixPointDice}/>
                </div>
              </div>
            </div>
            <div className="sicbo-table__body-column even-big-column">
              <div className="sicbo-table__body-tr">
                <div className="sicbo-table__body-tr-header">
                  <span className="sicbo-table__body-column-header-text">{getMarketPrice(0,1)}:1 Lose if any tripple</span>
                </div>
                <div className="sicbo-table__body-td-wrapper">
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={0} runnerIndex={1} textContent={"EVEN"}/>
                  <MarketSicbo getWinner={getWinner} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={1} runnerIndex={1} textContent={"BIG"} textContentNums={"11 - 17"}/>
                </div>
              </div>
            </div>
          </div>
          <div className="sicbo-table_footer">
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={1} textContent={"4"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={2} textContent={"5"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={3} textContent={"6"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={4} textContent={"7"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={5} textContent={"8"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={6} textContent={"9"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={7} textContent={"10"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={8} textContent={"11"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={9} textContent={"12"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={10} textContent={"13"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={11} textContent={"14"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={12} textContent={"15"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={13} textContent={"16"} getMarketPrice={getMarketPrice}/>
            <MarketSicbo getWinner={getWinner} footerCell={true} resultStatus={resultStatus} oddPush={oddPush} getExposure={getExposure} marketIndex={4} runnerIndex={14} textContent={"17"} getMarketPrice={getMarketPrice}/>
          </div>
        </div>
        <LastResults/>
      </div>
    )
  })

export default Sicbo


