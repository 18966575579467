import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks'
import { requestPromotions } from '../../store/promotions/promotions.thunks'
import { getInplayPopularSuccess } from '../../store/inplayPopular/inplayPopular.slice'
import { getPromotionsSuccess } from '../../store/promotions/promotions.slice'
import { requestOrderCount } from '../../store/orderCount/orderCount.thunks'
import { setLegalAgePopoverShow } from '../../store/modals/legalAgePopover.slice'
import { setKycPopoverShow } from '../../store/modals/kycPopover.slice'
import { setMoreRulesPopoverShow } from '../../store/modals/moreRulesPopover.slice'
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'
import {  setThisEvent } from '../../store/openBetsList/openBetsList.slice'
import { requestOpenBets } from '../../store/openBets/openBets.thunks'
import { requestCasinoPoint, requestLiveCasinoID, requestLiveCasinoLobby } from '../../store/liveCasino/liveCasino.thunks'
import { requestMenu } from '../../store/menu/menu.thunks'
import { getMenuSuccess } from '../../store/menu/menu.slice'
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import { setIsAnimatedMenu, setIsDetailedGroup, setIsMoreDetailed, setIsNavigationID, setIsNavigationName, setIsNavigationOpen, setRenderIonContent } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'

export const connectDefaultPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      lastActivityDate: state.balance.lastActivityDate,
      inplayPopular: state.inplayPopular,
      promotions: state.promotions,
      legalAgePopoverShow: state.legalAgePopoverShow,
      kycPopoverShow: state.kycPopoverShow,
      moreRulesPopoverShow: state.moreRulesPopoverShow,
      balanceSettings: state.balanceSettings,
      betPlacing: state.betPlacing,
      orderCount: state.openBets,
      timeSettings: state.timeSettings,
      elementsParams: state.elementsParams,
      fortuneWheel: state.fortuneWheel,
      liveCasino: state.liveCasino,
      isNavigationName: state.navigationHeaderMenu.navigationName,
      B2C: state.B2C,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestOrderCount,
      requestInplayPopular,
      requestPromotions,
      setLegalAgePopoverShow,
      setKycPopoverShow,
      setMoreRulesPopoverShow,
      getInplayPopularSuccess,
      getPromotionsSuccess,
      setLoginNew,
      setTab,
      setThisEvent,
      requestOpenBets,
      requestLiveCasinoLobby,
      requestLiveCasinoID,
      requestMenu,
      getMenuSuccess,
      setSlotCasinoPopUp,
      requestCasinoPoint,
      setIsNavigationOpen,
      setRenderIonContent,
      setIsAnimatedMenu,
      setIsNavigationID,
      setIsNavigationName,
      setIsMoreDetailed,
      setIsDetailedGroup,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
