import React from 'react'
import "./CardsInfo.scss";
import {useSelector} from "react-redux";
import {connectQueen} from "../../Queen/Queen.connect";
import {useParams} from "react-router-dom";
import RouletteLiveResult from "../../Roulette/RouletteHelpers/RouletteLiveResult/RouletteLiveResult";
import TwoCardTPCards from "../../TwoCardTeenPatti/TwoCardHelpers/TwoCardTPCards/TwoCardTPCards";
import TrioCards from "../../Trio/TrioHelpers/TrioCards/TrioCards";
import QueenCards from "../../Queen/QueenHelpers/QueenCards/QueenCards";
import TeenpattiTestCards from "../../TeenpattiTest/TeenpattiTestHelpers/TeenpattiTestCards/TeenpattiTestCards";
import TrapCards from "../../Trap/TrapHelpers/TrapCards/TrapCards";
import SicboCards from "../../Sicbo/SicboHelpers/SicboCards/SicboCards";
import MuflisTeenpattiCards from "../../MuflisTeenpatti/MuflisTeenpattiHelpers/MuflisTeenpattiCards/MuflisTeenpattiCards";
import Poker20Cards from "../../Poker20/Poker20Helpers/Poker20Cards/Poker20Cards";
import LiveTeenpattiCards from "../../LiveTeenpatti/LiveTeenpattiHelpers/LiveTeenpattiCards/LiveTeenpattiCards";
import TeenpattiT20Cards from "../../TeenpattiT20/TeenpattiT20Helpers/TeenpattiT20Cards/TeenpattiT20Cards";
import BaccaratCards from "../../Baccarat/BaccaratHelpers/BaccaratCards/BaccaratCards";
import CasinoMeterCards from "../../CasinoMeter/CasinoMeterHelpers/CasinoMeterCards/CasinoMeterCards";
import BollywoodCasinoCards from "../../BollywoodCasino/BollywoodCasinoHelpers/BollywoodCasinoCards/BollywoodCasinoCards";
import Race20Cards from "../../Race20/Race20Helpers/Race20Cards/Race20Cards";
import Casino32cardCards from "../../Casino32card/Casino32cardHelpers/Casino32cardCards/Casino32cardCards";
import PokerCards from "../../Poker/PokerHelpers/PokerCards/PokerCards";
import HiLowCards from "../../HiLow/HiLowHelpers/HiLowCards/HiLowCards";
import AndarBaharCards from "../../AndarBahar/AndarBaharHelpers/AndarBaharCards/AndarBaharCards";
import AmarAkbarAnthonyCards from "../../AmarAkbarAnthony/AmarAkbarAnthonyHelpers/AmarAkbarAnthonyCards/AmarAkbarAnthonyCards";
import SevenUpDownCards from "../../7UpDown/SevenUpDownHelpers/SevenUpDownCards/SevenUpDownCards";
import DragonTigerCards from "../../DragonTiger/DragonTigerHelpers/DragonTigerCards/DragonTigerCards";
import MatkaCards from "../../Matka/MatkaHelpers/MatkaCards/MatkaCards";
import SixPlayerPokerCards from '../../SixPlayerPoker/SixPlayerPokerHelpers/SixPlayerPokerCards/SixPlayerPokerCards';
import CasinoWarCards from '../../CasinoWar/CasinoWarHelpers/CasinoWarCards/CasinoWarCards'

const CardsInfo = () => {
    const {eventId} = useParams();

    // window width
    const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    const width = checkStateWidth ? checkStateWidth : window.innerWidth
    // end window width


    const virtualsGamesId = [
        "98792", /*Roulette*/
        "56966", /*32 Card Casino */
        "92036",
        "98793", /*7 up Down*/
        "56766", /*Teenpatti One-Day*/
        "56769", /*Teenpatti T20*/
        "56969", /*Hi Low*/
        "67563", /*Poker*/
        "87565", /*Andar Bahar*/
        "92036", /*Matka*/
        "98795",  /*AAA*/
        "67566", /*Six Player Poker*/
        "98794", /*dragonTiger*/
    ];

    const queenRaceCards = () => {
        if (eventId && eventId === "98788") {
            return (<RouletteLiveResult/>);
        }
        if (eventId === "67620") {
            return <QueenCards/>;
        }
        if (eventId === "67660") {
            return <TwoCardTPCards/>;
        }
        if (eventId === "67610") {
            return <TrioCards/>;
        }
        if (eventId === "67630"){
            return <TeenpattiTestCards/>;
        }
        if (eventId === "67680"){
            return <TrapCards/>;
        }
        if (eventId === "98566"){
            return <SicboCards/>;
        }
        if (eventId === "67600"){
            return <MuflisTeenpattiCards/>;
        }
        if (eventId === "67567"){
            return <Poker20Cards/>;
        }
        if (eventId === "56766" || eventId === "56767"){
            return <LiveTeenpattiCards/>;
        }
        if ((eventId && eventId === "56768") || (eventId && eventId === "56769")){
            return <TeenpattiT20Cards/>;
        }
        if ((eventId && eventId === "67575") || (eventId && eventId === "67575")){
            return <CasinoMeterCards/>;
        }
        if (eventId === "92038"){
            return <BaccaratCards/>;
        }
        if (eventId === "67570"){
            return <BollywoodCasinoCards/>;
        }
        if (eventId === "56967"){
            return <Casino32cardCards/>;
        }
        if ((eventId && eventId === "90100") || (eventId && eventId === "90101")) {
            return <Race20Cards/>;
        }
        if ((eventId === "67563") || (eventId === "67564")){
            return <PokerCards/>;
        }
        if ((eventId && eventId === "56968") || (eventId && eventId === "56969")) {
            return <HiLowCards/>;
        }
        if ((eventId && eventId === "87564") || (eventId && eventId === "87565")) {
            return <AndarBaharCards/>;
        }
        if ((eventId && eventId === "98791") || (eventId && eventId === "98795")) {
            return <AmarAkbarAnthonyCards/>;
        }
        if (eventId && (eventId === "98789" || eventId === "98793")) {
            return <SevenUpDownCards/>;
        }
        if ((eventId && eventId === "98790") || (eventId && eventId === "98794")) {
            return <DragonTigerCards/>;
        }
        if ((eventId && eventId === "92036") || (eventId && eventId === "92037")) {
            return <MatkaCards/>;
        }
        if ((eventId && eventId === "67565") || (eventId && eventId === "67566")) {
            return <SixPlayerPokerCards/>;
        }
        if (eventId && eventId === "67580") {
            return <CasinoWarCards/>;
        }
    };

    if ( (virtualsGamesId.includes(eventId) && width < 1024) || eventId === "98792"){
        return <></>;
    }

    return (
    <div className={`gameresult
     ${eventId === "67575" ? "cardsplace-casinoMeter" : ""} 
       ${((eventId === "90100")||(eventId === "90101")) ? "gameresult-race20" : ""} 
        ${eventId === "98788" ? "gameresult-roulette" : ""} 
         ${eventId === "98566" ? "gameresult-sicbo" : ""}
         ${((eventId === "92036")||(eventId === "92037")) ? "gameresult-matka" : ""}
          ${((eventId === "87564")||(eventId === "87565")) ? "gameresult-andar-bahar" : ""}
           ${((eventId === "56968")||(eventId === "56969")) ? "gameresult-high-low" : ""}
            ${eventId === "92038" ? "gameresult-baccarat" : ""}
             ${eventId === "67600" ? "gameresult-mulfis" : ""}
               ${eventId === "67580" ? "gameresult-casino-war" : ""}
                ${eventId === "67630" ? "gameresult-teenpatti-test" : ""}
                 ${eventId === "67660"? "gameresult-teenpatti2card" : ""}
                  ${eventId === "67680" ? "gameresult-trap" : ""}
                   ${eventId === "67610" ? "gameresult-trio" : ""}
                    ${eventId === "67567" ? "gameresult-poker20" : ""}
                     ${eventId === "67620" ? "gameresult-queen" : ""}
                      ${((eventId === "67563")||(eventId === "67564")) ? "gameresult-poker" : ""}
                       ${((eventId === "56966")||(eventId === "56967")) ? "gameresult-casino32-card" : ""}
                        ${((eventId === "67565")||(eventId === "67566")) ? "gameresult-poker-six-player" : ""}
                         ${((eventId === "56768")||(eventId === "56769")) ? "gameresult-t20-teenpatti" : ""}`}
    >
      <div className={`gameresult-greenline ${virtualsGamesId.includes(eventId) && "virtual"}`}></div>
      <div className={`gameresult-cardsplace 
                        ${eventId === "98788" ? "cardsplace-roulette" : ""} 
                          ${eventId === "98566" ? "cardsplace-sicbo" : ""}
                          ${((eventId === "98791")||(eventId === "98795")) ? "cardsplace-amar-akbar" : ""}
                            ${eventId === "67575" ? "cardsplace-casinoMeter" : ""}`} >
        {queenRaceCards()}
      </div>

      {/*<div className="gameresult-cardsplace">*/}
      {/*  {exchangeGames && eventId === '98788' && <RouletteLiveResult/>}*/}
      {/*</div>*/}

    </div>
    );
};

export default connectQueen()(CardsInfo);
