import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import './BonusCard.scss'
import infoIcon from '../../assets/images/info_iconWhite.svg'
import giftIcon from '../../assets/images/gift_icon.png'
import bonusExpired from '../../assets/images/bonusExpired.png'
import bonusClaimed from '../../assets/images/bonusClaimed.png'

import BonusCardDetailes from './BonusCardDetailes'
import CancelBonusPopUp from './CancelBonusPopUp'

const BonusCard = React.memo(() => {
  // const data = useSelector((state) => state.data)

  const slider = useRef(null)
  const [isDown, setIsDown] = useState(false)
  const [isSlide, setIsSlide] = useState(false)
  const [startX, setStartX] = useState('')
  const [scrollLeft, setScrollLeft] = useState('')

  const data = {
    bonusStatus: 'Active Bonus',
    currentWager: '1200',
    leftWager: '4500',
    expiresIn: 155200, // This represents 1 day, 18 hours, and 13 minutes in seconds
  }

  const [showBonusConditions, setShowBonusConditions] = useState(false)
  const [showCancelBonusPopUp, setShowCancelBonusPopUp] = useState(false)

  console.log('🚀 ~ file: BonusCard.js:5 ~ BonusCard ~ balance:', data)
  // Use useMemo to memoize formatted time and image source
  const formattedTime = useMemo(() => {
    // Function to format the time remaining
    const formatTime = (expiresIn) => {
      // Calculate days, hours, and minutes
      const days = Math.floor(expiresIn / (60 * 60 * 24))
      const hours = Math.floor((expiresIn % (60 * 60 * 24)) / (60 * 60))
      const minutes = Math.floor((expiresIn % (60 * 60)) / 60)

      return `${days} Days : ${hours} Hours : ${minutes} Minutes Left`
    }

    return formatTime(data.expiresIn)
  }, [data.expiresIn])

  const imageSource = useMemo(() => {
    // Function to determine the image source based on bonusStatus
    const getImageSource = (bonusStatus) => {
      // You can customize this logic based on your specific image requirements
      switch (bonusStatus) {
        case 'Active Bonus':
          return giftIcon
        case 'Expired':
          return giftIcon
        // Add more cases as needed
        default:
          return 'default.png'
      }
    }

    return getImageSource(data.bonusStatus)
  }, [data.bonusStatus])

  const percentageWagered = (
    (parseFloat(data.currentWager) /
      (parseFloat(data.currentWager) + parseFloat(data.leftWager))) *
    100
  ).toFixed(1)

  return (
    <>
      <div
        className="bonusCardGrid"
        ref={slider}
        style={{ gridTemplateColumns: '1fr' }}
        onMouseDown={(e) => {
          setIsDown(true)
          setStartX(e.pageX - slider.current.offsetLeft)
          setScrollLeft(slider.current.scrollLeft)
        }}
        onMouseUp={() => {
          if (isSlide) {
            setTimeout(() => setIsSlide(false), 500)
          }
          setIsDown(false)
        }}
        onMouseMove={(e) => {
          if (!isDown) return
          e.preventDefault()
          setIsSlide(true)
          const x = e.pageX - slider.current.offsetLeft
          const walk = (x - startX) * 3 //scroll-fast
          slider.current.scrollLeft = scrollLeft - walk
        }}
        onMouseLeave={() => {
          setIsDown(false)
          setIsSlide(false)
        }}
      >
        {/* ACTIVE WITHOUT CANCEL */}

        <div className="bonusCard">
          <div className="bonusCard_bonusStatus">
            <span>{data?.bonusStatus}</span>
          </div>
          <div className="bonusCard_mainInfo">
            <div className="bonusCard_mainInfo_headerCont">
              <div className="bonusCard_mainInfo_headerCont-title">
                Daily Deposit Bonus
              </div>
              <img
                className="bonusCard_mainInfo_headerCont-img"
                src={infoIcon}
                alt="Info"
                onClick={() => setShowBonusConditions(true)}
              />
            </div>
            <div className="bonusCard_mainInfo-timer">{formattedTime}</div>

            <div className="bonusCard_mainInfo_details">
              <div className="bonusCard_mainInfo_details_current">
                <div className="bonusCard_mainInfo_details_current-line"></div>
                <div className="bonusCard_mainInfo_details_current-title">
                  Current Wager:
                </div>
                <div className="bonusCard_mainInfo_details_current-sum">
                  ₹{data.currentWager}
                </div>
              </div>
              {data.leftWager ? (
                <div className="bonusCard_mainInfo_details_current">
                  <div className="bonusCard_mainInfo_details_current-line whiteLine"></div>
                  <div className="bonusCard_mainInfo_details_current-title">
                    Left Wager:
                  </div>
                  <div className="bonusCard_mainInfo_details_current-sum whiteSum">
                    ₹{data.leftWager}
                  </div>
                </div>
              ) : (
                <div className="bonusCard_mainInfo_details_claimButton">
                  Claim Bonus
                </div>
              )}
            </div>
            <div className="bonusCard_mainInfo_progressLine">
              <div className="bonusCard_mainInfo_progressLine_cont">
                <div
                  className="bonusCard_mainInfo_progressLine_cont-fill"
                  style={{ width: `${percentageWagered}%` }}
                ></div>
              </div>
              <span className="bonusCard_mainInfo_progressLine-percent">
                {percentageWagered}%
              </span>
            </div>
          </div>
          <div className="bonusCard_rightSide">
            <img src={imageSource} style={{ top: '25px' }} />
          </div>
          {showCancelBonusPopUp && (
            <CancelBonusPopUp
              onDidDismiss={() => setShowCancelBonusPopUp(false)}
            />
          )}

          {showBonusConditions && (
            <BonusCardDetailes
              onDidDismiss={() => setShowBonusConditions(false)}
              promo={''}
            />
          )}
        </div>

        {/* ACTIVE WITH CANCEL */}

        <div className="bonusCard">
          <div className="bonusCard_bonusStatus">
            <span>{data?.bonusStatus}</span>
          </div>
          <div className="bonusCard_mainInfo">
            <div className="bonusCard_mainInfo_headerCont">
              <div className="bonusCard_mainInfo_headerCont-title">
                Daily Deposit Bonus
              </div>
              <img
                className="bonusCard_mainInfo_headerCont-img"
                src={infoIcon}
                alt="Info"
                onClick={() => setShowBonusConditions(true)}
              />
            </div>
            <div className="bonusCard_mainInfo-timer">{formattedTime}</div>

            <div className="bonusCard_mainInfo_details">
              <div className="bonusCard_mainInfo_details_current">
                <div className="bonusCard_mainInfo_details_current-line"></div>
                <div className="bonusCard_mainInfo_details_current-title">
                  Current Wager:
                </div>
                <div className="bonusCard_mainInfo_details_current-sum">
                  ₹{data.currentWager}
                </div>
              </div>
              {data.leftWager ? (
                <div className="bonusCard_mainInfo_details_current">
                  <div className="bonusCard_mainInfo_details_current-line whiteLine"></div>
                  <div className="bonusCard_mainInfo_details_current-title">
                    Left Wager:
                  </div>
                  <div className="bonusCard_mainInfo_details_current-sum whiteSum">
                    ₹{data.leftWager}
                  </div>
                </div>
              ) : (
                <div className="bonusCard_mainInfo_details_claimButton">
                  Claim Bonus
                </div>
              )}
            </div>
            <div className="bonusCard_mainInfo_progressLine">
              <div className="bonusCard_mainInfo_progressLine_cont">
                <div
                  className="bonusCard_mainInfo_progressLine_cont-fill"
                  style={{ width: `${percentageWagered}%` }}
                ></div>
              </div>
              <span className="bonusCard_mainInfo_progressLine-percent">
                {percentageWagered}%
              </span>
            </div>
          </div>
          <div className="bonusCard_rightSide">
            <img src={imageSource} />
            <div
              className="bonusCard_rightSide_cancelButton"
              onClick={() => setShowCancelBonusPopUp(true)}
            >
              Cancel
            </div>
          </div>
          {showCancelBonusPopUp && (
            <CancelBonusPopUp
              onDidDismiss={() => setShowCancelBonusPopUp(false)}
            />
          )}

          {showBonusConditions && (
            <BonusCardDetailes
              onDidDismiss={() => setShowBonusConditions(false)}
              promo={''}
            />
          )}
        </div>

        {/* EXPIRED */}

        <div className="bonusCard">
          <div className="bonusCard_bonusStatus ">
            <span className="expiredBonus">Expired</span>
          </div>
          <div className="bonusCard_mainInfo">
            <div className="bonusCard_mainInfo_headerCont">
              <div className="bonusCard_mainInfo_headerCont-title">
                Daily Deposit Bonus
              </div>
              <img
                className="bonusCard_mainInfo_headerCont-img"
                src={infoIcon}
                alt="Info"
                onClick={() => setShowBonusConditions(true)}
              />
            </div>
            <div className="bonusCard_mainInfo-timer">No time left</div>

            <div className="bonusCard_mainInfo_details expiredBonus">
              <div className="bonusCard_mainInfo_details_current">
                <div className="bonusCard_mainInfo_details_current-line"></div>
                <div className="bonusCard_mainInfo_details_current-title">
                  Current Wager:
                </div>
                <div className="bonusCard_mainInfo_details_current-sum">
                  ₹{data.currentWager}
                </div>
              </div>
              {data.leftWager ? (
                <div className="bonusCard_mainInfo_details_current">
                  <div className="bonusCard_mainInfo_details_current-line whiteLine"></div>
                  <div className="bonusCard_mainInfo_details_current-title">
                    Left Wager:
                  </div>
                  <div className="bonusCard_mainInfo_details_current-sum whiteSum">
                    ₹{data.leftWager}
                  </div>
                </div>
              ) : (
                <div className="bonusCard_mainInfo_details_claimButton">
                  Claim Bonus
                </div>
              )}
            </div>
            <div className="bonusCard_mainInfo_progressLine expiredBonus">
              <div className="bonusCard_mainInfo_progressLine_cont">
                <div
                  className="bonusCard_mainInfo_progressLine_cont-fill"
                  style={{ width: `${percentageWagered}%` }}
                ></div>
              </div>
              <span className="bonusCard_mainInfo_progressLine-percent">
                {percentageWagered}%
              </span>
            </div>
          </div>
          <div className="bonusCard_rightSide">
            <img src={bonusExpired} />
            {/* <div className='bonusCard_rightSide_cancelButton' onClick={() => setShowCancelBonusPopUp(true)}>
        Cancel
    </div> */}
          </div>
          {showCancelBonusPopUp && (
            <CancelBonusPopUp
              onDidDismiss={() => setShowCancelBonusPopUp(false)}
            />
          )}

          {showBonusConditions && (
            <BonusCardDetailes
              onDidDismiss={() => setShowBonusConditions(false)}
              promo={''}
            />
          )}
        </div>

        {/* COMPLETED */}

        <div className="bonusCard">
          <div className="bonusCard_bonusStatus ">
            <span className="completedBonus">COMPLETED</span>
          </div>
          <div className="bonusCard_mainInfo">
            <div className="bonusCard_mainInfo_headerCont">
              <div className="bonusCard_mainInfo_headerCont-title">
                Daily Deposit Bonus
              </div>
              <img
                className="bonusCard_mainInfo_headerCont-img"
                src={infoIcon}
                alt="Info"
                onClick={() => setShowBonusConditions(true)}
              />
            </div>
            <div className="bonusCard_mainInfo-timer">No time left</div>

            <div className="bonusCard_mainInfo_details ">
              <div className="bonusCard_mainInfo_details_current">
                <div className="bonusCard_mainInfo_details_current-line"></div>
                <div className="bonusCard_mainInfo_details_current-title">
                  Current Wager:
                </div>
                <div className="bonusCard_mainInfo_details_current-sum">
                  ₹5700
                </div>
              </div>

              <div className="bonusCard_mainInfo_details_claimButton">
                Claim Bonus
              </div>
            </div>
            <div className="bonusCard_mainInfo_progressLine ">
              <div className="bonusCard_mainInfo_progressLine_cont">
                <div
                  className="bonusCard_mainInfo_progressLine_cont-fill"
                  style={{ width: `100%` }}
                ></div>
              </div>
              <span className="bonusCard_mainInfo_progressLine-percent">
                100%
              </span>
            </div>
          </div>
          <div className="bonusCard_rightSide">
            <img style={{ top: '20px' }} src={bonusClaimed} />
          </div>
          {showCancelBonusPopUp && (
            <CancelBonusPopUp
              onDidDismiss={() => setShowCancelBonusPopUp(false)}
            />
          )}

          {showBonusConditions && (
            <BonusCardDetailes
              onDidDismiss={() => setShowBonusConditions(false)}
              promo={''}
            />
          )}
        </div>

        {/* CLAIMED */}

        <div className="bonusCard">
          <div className="bonusCard_bonusStatus ">
            <span className="completedBonus">CLAIMED</span>
          </div>
          <div className="bonusCard_mainInfo">
            <div className="bonusCard_mainInfo_headerCont">
              <div className="bonusCard_mainInfo_headerCont-title">
                Daily Deposit Bonus
              </div>
              <img
                className="bonusCard_mainInfo_headerCont-img"
                src={infoIcon}
                alt="Info"
                onClick={() => setShowBonusConditions(true)}
              />
            </div>
            <div className="bonusCard_mainInfo-timer">
              Bonus claimed successfully!
            </div>

            <div className="bonusCard_mainInfo_details ">
              <div className="bonusCard_mainInfo_details_current">
                <div className="bonusCard_mainInfo_details_current-line"></div>
                <div className="bonusCard_mainInfo_details_current-title">
                  Claimed Bonus:
                </div>
                <div className="bonusCard_mainInfo_details_current-sum">
                  ₹5700
                </div>
              </div>
            </div>
            {/* <div className="bonusCard_mainInfo_progressLine ">
        <div className="bonusCard_mainInfo_progressLine_cont">
          <div
            className="bonusCard_mainInfo_progressLine_cont-fill"
            style={{ width: `100%` }}
          ></div>
        </div>
        <span className="bonusCard_mainInfo_progressLine-percent">100%</span>
      </div> */}
          </div>
          <div className="bonusCard_rightSide">
            <img style={{ top: '20px' }} src={bonusClaimed} />
          </div>
          {showCancelBonusPopUp && (
            <CancelBonusPopUp
              onDidDismiss={() => setShowCancelBonusPopUp(false)}
            />
          )}

          {showBonusConditions && (
            <BonusCardDetailes
              onDidDismiss={() => setShowBonusConditions(false)}
              promo={''}
            />
          )}
        </div>
      </div>
    </>
  )
})

export default BonusCard

// const CancelPopUp
