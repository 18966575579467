import './LeftSideMenu.scss'
import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonImg,
} from '@ionic/react'
import { connectLeftSideMenu } from './leftsidemenu.connect'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import { useHistory } from 'react-router'
import SlotsgamesIcon from '../../assets/images/sideBarSlotsgames.svg'
import downloadApk from '../../assets/images/menu-icons/apk_icon.svg'
import instaButton from '../../assets/images/socials/instagram.svg'
import { CONFIG } from '../../config/api.config'
import ReactGA from 'react-ga4'
import CricketIcon from '../../assets/images/sideBarCricket.svg'
import FootballIcon from '../../assets/images/sideBarFooltball.svg'
import TennisIcon from '../../assets/images/sideBarTennis.svg'
import HorseRacingIcon from '../../assets/images/sideBarHorse.svg'
import GreyhoundIcon from '../../assets/images/sideBarGreyhound.svg'

const LeftSideMenu = ({
  menu,
  auth,
  menuTop,
  version,
  promotion,
  B2C,
  actions,
}) => {
  const history = useHistory()

  const getMapMenu = (menuState) => {
    const collectionPages = Object.assign([], menuState)
    collectionPages.push({
      type: 'EVENT_TYPE',
      name: 'Aviator',
      id: '67722-2',
      sortOrder: -2,
      redirect: '/casino_live/67722-2/FAWK',
      isSvg: true,
      children: [
        {
          children: [],
          name: 'Aviator',
          id: '67722-2',
          type: 'LIVE_CASINO',
          providerId: 7,
        },
      ],
    })

    return collectionPages
      .map((menuItem) => {
        const menuHelperItem = Object.entries(leftSideMenuMapper)
          .map(([key, value]) => value)
          .find((menuItemWrapper) => menuItem.id == menuItemWrapper.id)
        return {
          ...menuItem,
          img: menuHelperItem ? menuHelperItem.img : '',
          isSvg: menuHelperItem ? menuHelperItem.isSvg : '',
          name: menuHelperItem ? menuHelperItem.name : menuItem.name,
        }
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
  }

  const [menuItemsMap, setMenuItemsMap] = useState([])

  useEffect(() => {
    setMenuItemsMap(getMapMenu(menu || []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  useEffect(() => {
    actions.requestMenuTop()
  }, [])

  const getMenuItems = () => {
    return menuItemsMap.map((value, index) => {
      return (
        <IonItem
          className="left-side-menu-item"
          key={index}
          onClick={(e) => {
            ReactGA.event({
              label: value.name,
              category: 'User-Click',
              action: value.name + ' Left_Menu_Click',
            })

            let page
            if (value.id == '1444000') {
              actions.setSlotCasinoPopUp(true)
              page = '/livecasino-lobby'
            } else if (value.id == '1444005') page = `/sportsbook/${value.id}`
            else if (value.id == '1444001') page = `/exchange-games`
            else if (value.redirect != undefined) {
              page = value.redirect
            } else page = `/menu-event/${value.id}`

            if (!page) {
              actions.setLoginNew(true)
              history.push({ pathname: '/default-page' })
            } else {
              history.push({
                pathname: page,
                state: { id: value.id },
              })
            }
          }}
        >
          {value.isSvg ? (
            <IonImg
              className="left-side-menu-item__icon eventIcon svg"
              src={value.img}
            />
          ) : (
            <span
              className={`left-side-menu-item__icon eventIcon ${value.name.replace(
                /\s/g,
                ''
              )}`}
            >
              {value.img}
            </span>
          )}
          <IonLabel className="left-side-menu-item__label">
            {value.name}
          </IonLabel>
        </IonItem>
      )
    })
  }
  console.log('promotions leftside', promotion)
  const getMenu = (data) => {
    actions.requestMenu(data).catch((e) => console.log('menu error = ', e))
  }

  function getGameImage(sportId) {
    // Initialize variables for banner and logo-square URLs
    let image = null

    if (sportId === '4') {
      image = CricketIcon
    } else if (sportId === '1') {
      image = FootballIcon
    } else if (sportId === '2') {
      image = TennisIcon
    } else if (sportId === '7') {
      image = HorseRacingIcon
    } else if (sportId === '4339') {
      image = GreyhoundIcon
    } else if (sportId === '-100') {
      image = SlotsgamesIcon
    } else if (sportId === 'QT') {
      image = SlotsgamesIcon
    } else {
      image = SlotsgamesIcon
    }

    // Return the banner image if available, otherwise return the logo-square image
    return image
  }

  return (
    <IonMenu
      side="start"
      id="LeftSide"
      menuId="LeftSide"
      contentId="LeftSide"
      type="push"
      swipeGesture={false}
      className="left-side-menu"
    >
      <IonContent>
        <IonList className="left-side-menu-list">
          <IonItem
            className="left-side-menu-item"
            key="mymarket"
            onClick={(e) => {
              auth ? history.push('/my_markets/') : actions.setLoginNew(true)
            }}
          >
            <span className="left-side-menu-item__icon"></span>
            <IonLabel className="left-side-menu-item__label">
              My markets
            </IonLabel>
          </IonItem>
          {/* TODO: with mobile left side menu work here */}
          {/* {getMenuItems()} */}
          {menuTop.map((item) => {
            return (
              <IonItem
                className="left-side-menu-item"
                key="cricket"
                onClick={() => {
                  if (item.sport_id === 'QT' || item.sport_id === '-100') {
                    actions.setSlotCasinoPopUp(true)
                    actions.requestCasinoPoint()
                  }

                  if (item.sport_id === '-100') {
                    history.push('/exchange-games')
                    return
                  }

                  const sport = { sport_id: item.sport_id }
                  getMenu(sport)
                  let page = `/menu-event/${item.sport_id}`
                  history.push({
                    pathname: page,
                    state: { id: item.sport_id },
                  })
                }}
              >
                <IonImg
                  className="left-side-menu-item__icon eventIcon svg"
                  src={getGameImage(item?.sport_id)}
                />
                <IonLabel className="left-side-menu-item__label">
                  {item.name}
                </IonLabel>
              </IonItem>
            )
          })}
          <IonItem
            className="left-side-menu-item"
            onClick={() => {
              history.push('/livecasino-lobby')
              actions.setSlotCasinoPopUp(true)
              actions.requestCasinoPoint()
            }}
          >
            <IonImg
              className="left-side-menu-item__icon eventIcon svg"
              src={SlotsgamesIcon}
            />
            <IonLabel className="left-side-menu-item__label">
              Live Casino
            </IonLabel>
          </IonItem>
          <IonItem
            className="left-side-menu-item"
            onClick={() => {
              history.push('/slotsgames-lobby')
              actions.setSlotCasinoPopUp(true)
              actions.requestCasinoPoint()
            }}
          >
            <IonImg
              className="left-side-menu-item__icon eventIcon svg"
              src={SlotsgamesIcon}
            />
            <IonLabel className="left-side-menu-item__label">
              Slots Games
            </IonLabel>
          </IonItem>

          {/* <IonItem className="left-side-menu-item" key="cricket" onClick={() => {
              const cricket = {'sport_id': '4'}
              getMenu(cricket)
              let page = `/menu-event/${cricket.sport_id}`
              history.push({
                pathname: page,
                state: { id: cricket.sport_id }
              });
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={CricketIcon} />
            <IonLabel className="left-side-menu-item__label">Cricket</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" key="tennis" onClick={() => {
              const tennis = {'sport_id': '2'}
              getMenu(tennis)
              let page = `/menu-event/${tennis.sport_id}`
              history.push({
                pathname: page,
                state: { id: tennis.sport_id }
              });
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={TennisIcon} />
            <IonLabel className="left-side-menu-item__label">Tennis</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" key="football" onClick={() => {
                const soccer = {'sport_id': '1'}
                getMenu(soccer)
                let page = `/menu-event/${soccer.sport_id}`
                history.push({
                pathname: page,
                state: { id: soccer.sport_id }
              });
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={FootballIcon} />
            <IonLabel className="left-side-menu-item__label">Soccer</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" key="horseRacing" onClick={() => {
                const horseRacing = {'sport_id': '7'}
                getMenu(horseRacing)
                let page = `/menu-event/${horseRacing.sport_id}`
                history.push({
                pathname: page,
                state: { id: horseRacing.sport_id }
              });
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={HorseRacingIcon} />
            <IonLabel className="left-side-menu-item__label">Horse Racing</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" key="Greyhound Racing" onClick={() => {
                const greyhoundRacing = {'sport_id': '4339'}
                getMenu(greyhoundRacing)
                let page = `/menu-event/${greyhoundRacing.sport_id}`
                history.push({
                pathname: page,
                state: { id: greyhoundRacing.sport_id }
              });
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={GreyhoundIcon} />
            <IonLabel className="left-side-menu-item__label">Greyhound Racing</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" onClick={() => {
            history.push("/slotsgames-lobby")
            actions.setSlotCasinoPopUp(true)
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={SlotsgamesIcon} />
            <IonLabel className="left-side-menu-item__label">Slots Games</IonLabel>
          </IonItem> */}
          {/* <IonItem className="left-side-menu-item" onClick={() => {
            ReactGA.event({
              label: "Download_App",
              category: "User-Click",
              action: "Left_Menu_Download_App"
            });
            window.location.href = CONFIG.downloadAppLink
          }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={downloadApk} />
            <IonLabel className="left-side-menu-item__label">Download APK</IonLabel>
          </IonItem> */}
        </IonList>
        {/* TODO: add from API */}
        {B2C && (
          <div className="socials">
            <IonLabel className="left-side-menu-item__label_Whatsapp">
              Get Instant ID on whatsapp
            </IonLabel>
            {promotion && promotion.Whatsapp && (
              <a href={promotion.Whatsapp.url} target={'_blank'}>
                <div className="whatsapp-button" />
              </a>
            )}
            {promotion && promotion?.Telegram && (
              <a href={promotion?.Telegram.url} target={'_blank'}>
                <div className="telegram-support-button" />
              </a>
            )}

            {promotion && promotion?.Instagram && (
              <a href={`${promotion?.Instagram.url}`} target={'_blank'}>
                <div className="instagram-button" />
              </a>
            )}

            {/* {promotion && promotion.Telegram && (
            <a href={`https://telegram.me/${CONFIG.telegram}`} target={"_blank"}>
              <div  className="telegram-button" />
            </a>
          )} */}

            {/* <a href={CONFIG.whatsappSupportLink} target={"_blank"}>
            <div  className="whatsapp-care-button" />
          </a> */}
          </div>
        )}
      </IonContent>
    </IonMenu>
  )
}

export default connectLeftSideMenu()(LeftSideMenu)
