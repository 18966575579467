import React, { useEffect, useState } from 'react'
import './BettingProfitAndLossPage.scss'
import { connectBettingProfitAndLosePage } from './BettingProfitAndLossPage.connect'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Toolbar from '../../components/Toolbar/Toolbar'
import { timeToDate, daysFromTo } from '../../helpers/date.helper'
import BettingProfitAndLossItemList from '../../components/BettingProfitAndLoseItemList/BettingProfitAndLossItemList'
import { useHistory } from 'react-router'
import BettingProfitAndLossDetailsTable from '../../components/BettingProfitAndLossDetailsTable/BettingProfitAndLossDetailsTable'
import DatePicker from 'react-date-picker'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import {
  getCurrencyFormat,
  getCurrencyFormatWithZeroAfterDot,
} from '../../helpers/getCurrencyFormat.helper'
import BettingProfitAndLossCasinoDetailsTable from '../../components/BettingProfitAndLossCasinoDetailsTable/BettingProfitAndLossCasinoDetailsTable'

const BettingProfitAndLossPage = ({
  user,
  accountReport,
  actions,
  lastActivityDate,
  timeSettings,
  orderList,
  elementsParams,
}) => {
  //get window with
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth.clientWidth
  //end get window with

  // States
  const history = useHistory()
  const [accountReportMap, setAccountReportMap] = useState(new Map())
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [fromToDays, setFromToDays] = useState(0)
  const [gameButtonsNames, setGameButtonsNames] = useState([])
  const [totalAmount, setTotalAmount] = useState('')
  const [activeSportButton, setActiveSportButton] = useState(null)
  const [selectedItemDetails, setSelectedItemDetails] = useState(null)
  const [validationError, setValidationError] = useState(null)

  const [filterOpen, setfilterOpen] = useState(false)
  const [choosenFrom, setChoosenFrom] = useState(false)
  const [choosenTo, setChoosenTo] = useState(false)
  const [selectPNLItem, setSelectPNLItem] = useState(null)

  // End States

  function getLastTimeOfDay(dateInput) {
    const date = new Date(dateInput)
    date.setUTCHours(23, 59, 59, 999)

    return date.toISOString()
  }

  function getStartTimeOfDay(dateInput) {
    const date = new Date(dateInput)
    date.setUTCHours(0, 0, 0, 0)

    return date.toISOString()
  }

  //create top filter Buttons
  const createButtons = () => {
    if (!accountReport) {
      return
    }
    let buttonsNames = []
    let buttonsTemp = Object.values(accountReport)
    console.log('')

    buttonsTemp.forEach((item) => {
      const existingEntry = buttonsNames.find(
        (button) => button.eventTypeId === item?.sport_id
      )

      if (existingEntry) {
        // If entry exists, add net_pl to the existing totalPnl
        existingEntry.totalPnl += item?.net_pl
      } else {
        // If entry does not exist, create a new entry
        buttonsNames.push({
          buttonName: item?.sport_name,
          eventTypeId: item?.sport_id,
          totalPnl: item?.net_pl,
        })
      }
    })

    // buttonsTemp.map((item) => buttonsNames.push({
    //     buttonName: item?.sport_name,
    //     eventTypeId: item?.sport_id,
    //     totalPnl: item?.net_pl,
    // }));
    setGameButtonsNames(buttonsNames)
    let sum = buttonsNames
      .reduce((acc, item) => acc + item.totalPnl, 0)
      .toFixed(2)
    setTotalAmount(sum)
  }
  // End create top filter Buttons

  let todayDate = new Date()

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }

  const getWithTimeLimit = () => {
    setSelectPNLItem(null)
    const DateFrom =
      selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0)
    const DateTo =
      selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999)
    dateValidation(DateFrom, DateTo)
  }

  // main events table
  const buildAccountReportMap = (accountReport, gameId = false) => {
    if (width > 1024) {
      if (!accountReport) {
        return
      }

      accountReportMap.clear()
      Object.entries(accountReport).forEach(([key, d]) => {
        const ketMap = timeToDate(d.result_date)
        const mapValue = accountReportMap.get(ketMap.time)
        if (mapValue) {
          if (gameId) {
            if (gameId === d.sport_id) {
              mapValue.data.push(d)
            }
          } else {
            mapValue.data.push(d)
          }
        } else {
          if (gameId) {
            if (gameId && gameId === d.sport_id) {
              accountReportMap.set(ketMap.time, {
                dateString: ketMap.dateString,
                data: [d],
                time: ketMap.time,
              })
            }
          } else {
            accountReportMap.set(ketMap.time, {
              dateString: ketMap.dateString,
              data: [d],
              time: ketMap.time,
            })
          }
        }
      })
      setAccountReportMap(
        new Map([...accountReportMap].sort((a, b) => b[0] - a[0]))
      )
    } else if (width < 1024) {
      if (!accountReport) {
        return
      }
      accountReportMap.clear()
      Object.entries(accountReport).forEach(([key, d]) => {
        const ketMap = timeToDate(d.result_date)

        const mapValue = accountReportMap.get(ketMap.time)
        if (mapValue) {
          mapValue.data.push(d)
        } else {
          accountReportMap.set(ketMap.time, {
            dateString: ketMap.dateString,
            data: [d],
          })
        }
      })
      setAccountReportMap(
        new Map([...accountReportMap].sort((a, b) => b[0] - a[0]))
      )
    }
  }
  // End main events table

  // get Data from api With Time Limit
  const getDataWithTimeLimit = (DateFrom, DateTo) => {
    user &&
      history.location.pathname === '/betting_profit_and_loss' &&
      !validationError &&
      actions
        .requestAccountReport({
          fromDate: getStartTimeOfDay(DateFrom),
          toDate: getLastTimeOfDay(DateTo),
        })
        .then(() => console.log('error'))
        .catch((e) => console.log('requestAccountReport error = ', e))
  }
  // End get Data from api With Time Limit

  //  Date validation and run getDataWithTimeLimit
  const dateValidation = (from, to) => {
    if (from > to)
      return setValidationError("'To' date must be greater than 'From' date.")
    else if (setValidationError) setValidationError(null)
    if (new Date().setHours(23, 59, 59, 999) < to)
      return setValidationError(
        "'To' date must not be greater than today's date."
      )
    else if (setValidationError) setValidationError(null)

    if (validationError === null) {
      let DateFrom = selectedDateFrom && new Date(from).setHours(0, 0, 0, 0)
      let DateTo = selectedDateTo && new Date(to).setHours(23, 59, 59, 999)
      getDataWithTimeLimit(DateFrom, DateTo)
    }
  }
  //  End  Date validation and run getDataWithTimeLimit

  //request events with date (main function)
  const requestWithTimeLimit = () => {
    const DateFrom =
      selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0)
    const DateTo =
      selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999)

    dateValidation(DateFrom, DateTo)
  }
  // End request events with date (main function)

  //table first render
  useEffect(() => {
    user &&
      history.location.pathname === '/betting_profit_and_loss' &&
      actions
        .requestAccountReport({
          fromDate: getStartTimeOfDay(selectedDateFrom),
          toDate: getLastTimeOfDay(selectedDateTo),
        })
        .catch((e) => console.log('requestAccountReport error = ', e))
  }, [history.location.pathname, lastActivityDate])
  // End table first render

  // re-render if sport selected
  useEffect(() => {
    if (width > 1024) {
      if (activeSportButton) {
        buildAccountReportMap(accountReport, activeSportButton)
      } else {
        buildAccountReportMap(accountReport)
      }
      createButtons(accountReport)
    } else {
      buildAccountReportMap(accountReport)
    }
  }, [accountReport, activeSportButton])
  // End re-render if sport selected

  // set Active Sport Button if details selected
  useEffect(() => {
    if (width > 1024) {
      if (selectedItemDetails?.bet_id) {
        setActiveSportButton(selectedItemDetails.bet_id)
      }
    }
  }, [selectedItemDetails])
  // End  set Active Sport Button if details selected

  const fetchPNLData = (providerId) => {
    console.log('providerId', providerId)
    if (providerId.sport_id === '-100' || providerId.sport_id === 'QT') {
      const data = {
        bets_type: 'settled',
        roundId: providerId.event_id.split('.').pop(),
      }

      actions
        .requestCasinoBetHistorySuccess(data)
        .then((data) =>
          goToDetailsPage(data.data?.data?.data, providerId.sport_id)
        )
        .catch((e) => console.log('requestBetHistoryError = ', e))
      return
    }

    const data = {
      match_id: providerId.match_id,
    }
    actions
      .requestBetHistorySuccess(data)
      .then((data) =>
        goToDetailsPage(data.data?.data[0]?.data, providerId.sport_id)
      )
      .catch((e) => console.log('requestBetHistoryError = ', e))
  }

  const goToDetailsPage = (data, sportId) => {
    if (width < 1024) {
      history.push({
        pathname:
          sportId === '-100' || sportId === 'QT'
            ? 'betting_profit_and_loss_casino_details'
            : '/betting_profit_and_loss_details',
        state: { itemData: data },
      })
    } else {
      setSelectedItemDetails(data)
    }
  }

  if (width < 1024) {
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="betting-profit-and-loss-page" fullscreen>
          <div className="page-title-wrapper">
            <h2 className="page-title">Betting Profit & Loss</h2>
          </div>

          <div className="account-statement-page__filter-content">
            <div>
              <button
                onClick={() => {
                  setfilterOpen((prevValue) => !prevValue)
                  // setChoosenFrom(false)
                  // setChoosenTo(false)
                }}
                className={`filter-button ${
                  filterOpen && 'filter-button--active'
                }`}
              >
                Filter
              </button>
            </div>

            {filterOpen && (
              <div className="account-statement-page__filter-content">
                <div
                  className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenFrom(true)
                  }}
                >
                  <p
                    style={
                      choosenFrom
                        ? { fontSize: '12px', transition: 'linear 0.2s' }
                        : {}
                    }
                  >
                    Date from:
                  </p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateFrom}
                      value={selectedDateFrom}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <div
                  className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenTo(true)
                  }}
                >
                  <p
                    style={
                      choosenTo
                        ? { fontSize: '12px', transition: 'linear 0.2s' }
                        : {}
                    }
                  >
                    Date to:
                  </p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateTo}
                      value={selectedDateTo}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <button
                  className="account-statement-page__date-search-button"
                  // disabled={!(selectedDateFrom && selectedDateTo)}
                  onClick={() => {
                    getWithTimeLimit()
                    setfilterOpen((prevValue) => !prevValue)
                    setFromToDays(daysFromTo(selectedDateFrom, selectedDateTo))
                  }}
                >
                  <span>Apply</span>
                </button>
                {validationError && (
                  <div className="account-statement-page__filter-content-error">
                    {validationError}
                  </div>
                )}
              </div>
            )}
          </div>

          {accountReportMap.size === 0 ? (
            <div className="betting-profit-and-loss-page__content">
              <div className="betting-profit-and-loss-page__content--empty">
                {fromToDays
                  ? `There are no settled bets in selected  ${
                      fromToDays > 1 ? `${fromToDays} days` : 'day'
                    }`
                  : 'There are no settled bets in last 7 days'}
              </div>
            </div>
          ) : (
            <div className="betting-profit-and-loss-page__item-list">
              {[...accountReportMap].map(([key, value]) => {
                return (
                  <BettingProfitAndLossItemList
                    key={key}
                    listItemsObject={value}
                  />
                )
              })}
            </div>
          )}
        </IonContent>
      </IonPage>
    )
  }

  return (
    <div className="betting-profit-and-loss-page" fullscreen>
      <div className="page-title-wrapper">
        <h2 className="page-title">Betting Profit & Loss</h2>
      </div>
      <div className="betting-profit-and-loss-page__content">
        <div className="betting-profit-and-loss-page__filters-wrapper">
          <div className="betting-profit-and-loss-page__filters-date">
            <div
              className="betting-profit-and-loss-page__filters-date-input"
              style={{ marginRight: '15px' }}
            >
              <p>From</p>
              <div>
                <DatePicker
                  onChange={setSelectedDateFrom}
                  value={selectedDateFrom}
                  locale={'en-US'}
                  showLeadingZeros={true}
                  clearIcon={null}
                  calendarIcon={null}
                  format={'dd/MM/yyyy'}
                  dayPlaceholder={getDate(todayDate)}
                  monthPlaceholder={getMonth(todayDate)}
                  yearPlaceholder={todayDate.getFullYear()}
                />
              </div>
            </div>
            <div
              className="betting-profit-and-loss-page__filters-date-input"
              style={{ marginRight: '15px' }}
            >
              <p>To</p>
              <div>
                <DatePicker
                  onChange={setSelectedDateTo}
                  value={selectedDateTo}
                  locale={'en-US'}
                  showLeadingZeros={true}
                  clearIcon={null}
                  calendarIcon={null}
                  format={'dd/MM/yyyy'}
                  dayPlaceholder={getDate(todayDate)}
                  monthPlaceholder={getMonth(todayDate)}
                  yearPlaceholder={todayDate.getFullYear()}
                />
              </div>
            </div>
            <button
              className="betting-profit-and-loss-page__date-search-button"
              // disabled={!(selectedDateFrom && selectedDateTo)}
              onClick={() => {
                requestWithTimeLimit()
                setFromToDays(daysFromTo(selectedDateFrom, selectedDateTo))
              }}
            >
              <span>
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span>Search</span>
            </button>
            {validationError && (
              <div className="betting-profit-and-loss-page__page-menu-filter-content-error">
                {validationError}
              </div>
            )}
          </div>
          {accountReportMap.size === 0 ? (
            ''
          ) : (
            <div className="betting-profit-and-loss-page__filters-sports-buttons-total">
              <ul className="betting-profit-and-loss-page__filters-sports-buttons-list">
                {gameButtonsNames &&
                  gameButtonsNames.map((value, key) => {
                    return (
                      <li
                        className={`${
                          activeSportButton === value.eventTypeId
                            ? 'active-button'
                            : ''
                        } betting-profit-and-loss-page__filters-sport-button`}
                        key={value.buttonName + key}
                        onClick={() => {
                          buildAccountReportMap(
                            accountReport,
                            value.eventTypeId
                          )
                          setActiveSportButton(value.eventTypeId)
                          setSelectedItemDetails(null)
                        }}
                      >
                        <span className="betting-profit-and-loss-page__filters-sport-button-name">
                          {value.buttonName}:
                        </span>
                        &nbsp;
                        <span
                          className={`${
                            value.totalPnl > 0
                              ? 'positive-amount'
                              : 'negative-amount'
                          } betting-profit-and-loss-page__filters-sport-amount`}
                        >
                          {/* {value.totalPnl.toFixed(2)} */}
                          {getCurrencyFormatWithZeroAfterDot(value.totalPnl)}
                        </span>
                      </li>
                    )
                  })}
              </ul>
              <div
                className={`${
                  activeSportButton === null ? 'active-button' : ''
                } betting-profit-and-loss-page__filters-total`}
                onClick={() => {
                  buildAccountReportMap(accountReport)
                  setActiveSportButton(null)
                  setSelectedItemDetails(null)
                }}
              >
                <span>Total P&L:</span>
                &nbsp; &nbsp;
                <span
                  className={`${
                    totalAmount && totalAmount > 0
                      ? 'positive-amount'
                      : 'negative-amount'
                  } betting-profit-and-loss-page__filters-total-amount`}
                >
                  {totalAmount &&
                    totalAmount !== '' &&
                    getCurrencyFormatWithZeroAfterDot(totalAmount)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="betting-profit-and-loss-page__table">
          {!selectedItemDetails && (
            <>
              <ul className="betting-profit-and-loss-page__table-head">
                <li>
                  <span>Market</span>
                </li>
                <li>
                  <span>Start time</span>
                </li>
                <li>
                  <span>Settled time</span>
                </li>
                <li>
                  <span>Comm.</span>
                </li>
                <li>
                  <span>Net Win</span>
                </li>
              </ul>
              {accountReportMap.size === 0 ? (
                <div className="betting-profit-and-loss-page__content">
                  <div className="betting-profit-and-loss-page__content--empty">
                    {fromToDays
                      ? `There are no settled bets in selected  ${
                          fromToDays > 1 ? `${fromToDays} days` : 'day'
                        }`
                      : 'There are no settled bets in last 7 days'}
                  </div>
                </div>
              ) : (
                [...accountReportMap].map(([key, value]) => {
                  return (
                    <BettingProfitAndLossItemList
                      key={key}
                      listItemsObject={value}
                      setSelectedItemDetails={fetchPNLData}
                    />
                  )
                })
              )}
            </>
          )}
          {selectedItemDetails && (
            <>
              {selectedItemDetails[0].sport_id ? (
                <BettingProfitAndLossDetailsTable
                  width={width}
                  itemDetails={selectedItemDetails}
                  selectedItemDetails={selectedItemDetails}
                  timeSettings={timeSettings}
                  setSelectedItemDetails={setSelectedItemDetails}
                  breadcrumbNames={
                    gameButtonsNames.filter(
                      (item) =>
                        item.eventTypeId === selectedItemDetails.length > 0 &&
                        selectedItemDetails[0].sport_id
                    )[0]
                  }
                />
              ) : (
                <BettingProfitAndLossCasinoDetailsTable
                  width={width}
                  itemDetails={selectedItemDetails}
                  selectedItemDetails={selectedItemDetails}
                  timeSettings={timeSettings}
                  setSelectedItemDetails={setSelectedItemDetails}
                  breadcrumbNames={
                    gameButtonsNames.filter(
                      (item) =>
                        item.eventTypeId === selectedItemDetails.length > 0 &&
                        selectedItemDetails[0].sport_id
                    )[0]
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default connectBettingProfitAndLosePage()(BettingProfitAndLossPage)
