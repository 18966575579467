import React, { useState, useEffect, useRef } from 'react'
import './BetSettingsDesktop.scss'
import { IonInput} from '@ionic/react'
import { connectBetSettingsDesktop } from './BetSettingsDesktop.connect'

const BetSettingsDesktop = ({ user, actions }) => {
  const [checked, setChecked] = useState(
    user?.settings?.oneClickSettings?.active || false
  )
  const [activeStakeSelected, setActiveStakeSelected] = useState(
    user?.settings?.oneClickSettings?.selected || false
  )
  const [editOneclick, setEditOneclick] = useState(false)
  const [firstOneclick, setFirstOneclick] = useState(
    user?.settings?.oneClickSettings?.settings[0] || false
  )
  const [secondOneclick, setSecondOneclick] = useState(
    user?.settings?.oneClickSettings?.settings[1] || false
  )
  const [thirdOneclick, setThirdOneclick] = useState(
    user?.settings?.oneClickSettings?.settings[2] || false
  )
  const [isEditVisible, setIsEditVisible] = useState(false)

  const saveOneClickSettings = (withApi = false) => {
    const settings = {
      active: checked,
      selected: activeStakeSelected,
      settings: [
        parseInt(firstOneclick),
        parseInt(secondOneclick),
        parseInt(thirdOneclick),
      ],
    }
    actions.requestSaveOneClickSettings(user?.memberCode, settings, withApi)
  }

  const toggleOneclick = (e) => {
    setChecked(!user?.settings?.oneClickSettings?.active)
    setEditOneclick(false)
    setIsEditVisible(!isEditVisible)
  }
  const activeStakeClick = (item) => {
    if (!editOneclick && checked) {
      setActiveStakeSelected(item)
    }
  }
  const firstInputRef = useRef()

  useEffect(() => {
    saveOneClickSettings()
  }, [checked, saveOneClickSettings])

  useEffect(() => {
    if (activeStakeSelected !== user?.settings?.oneClickSettings?.selected)
      saveOneClickSettings(true)
  }, [activeStakeSelected, user, saveOneClickSettings])

  return (
    <div className="BetSettingsDesktop">
      <div className="BetSettingsDesktop__toggle">
        <div className="newToggler">
          <label className="switcher">
            <input
              type="checkbox"
              checked={user.settings.oneClickSettings.active}
              onChange={toggleOneclick}
            />
            <span class="slider round"></span>
          </label>
          {!user.settings.oneClickSettings.active && (
            <span className="BetSettingsDesktop__oneClick">
              1 Click Betting
            </span>
          )}
        </div>
        {user.settings.oneClickSettings.active && (
          <div
            className={
              user.settings.oneClickSettings.active
                ? 'BetSettingsDesktop__item'
                : 'BetSettingsDesktop__item inputsHidden'
            }
          >
            <div
              className={`BetSettingsDesktop__fields ${
                editOneclick ? 'on-edit' : ''
              }`}
            >
              <IonInput
                ref={firstInputRef}
                className={`BetSettingsDesktop__input ${
                  activeStakeSelected == 1 ? 'active-stake' : ''
                }`}
                placeholder="0"
                name="firstOneclick"
                disabled={!editOneclick}
                type="text"
                value={firstOneclick}
                onClick={() => activeStakeClick(1)}
                onIonChange={(e) => {
                  const val = Number(e.target.value)
                  if (!Number.isInteger(val)) return
                  setFirstOneclick(val)
                }}
              />
              <IonInput
                className={`BetSettingsDesktop__input ${
                  activeStakeSelected == 2 ? 'active-stake' : ''
                }`}
                placeholder="0"
                name="secondOneclick"
                disabled={!editOneclick}
                type="text"
                value={secondOneclick}
                onClick={() => activeStakeClick(2)}
                onIonChange={(e) => {
                  const val = Number(e.target.value)
                  if (!Number.isInteger(val)) return
                  setSecondOneclick(val)
                }}
              />
              <IonInput
                className={`BetSettingsDesktop__input ${
                  activeStakeSelected == 3 ? 'active-stake' : ''
                }`}
                placeholder="0"
                name="thirdOneclick"
                disabled={!editOneclick}
                type="text"
                value={thirdOneclick}
                onClick={() => activeStakeClick(3)}
                onIonChange={(e) => {
                  const val = Number(e.target.value)
                  if (!Number.isInteger(val)) return
                  setThirdOneclick(val)
                }}
              />
            </div>
          </div>
        )}
        {user.settings.oneClickSettings.active && (
          <>
            {editOneclick ? (
              <button
                onClick={() => {
                  saveOneClickSettings(true)
                  setEditOneclick(false)
                }}
                className="BetSettingsDesktop__btn"
                disabled={false}
                mode="md"
              >
                Save
              </button>
            ) : (
              <button
                onClick={() => {
                  setEditOneclick(true)
                  setTimeout(() => {
                    firstInputRef.current.focus()
                  }, 500)
                }}
                className="BetSettingsDesktop__btn"
                disabled={!checked}
                mode="md"
              >
                Edit
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default connectBetSettingsDesktop()(BetSettingsDesktop)
