import React from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./Trap.scss"
import { connectTrap } from './Trap.connect';
import { useEffect } from 'react';
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';
import TheTrapJ from '../../../assets/images/exchangeGames/theTrap/theTrapJ.svg'
import TheTrapQ from '../../../assets/images/exchangeGames/theTrap/theTrapQ.svg'
import TheTrapK from '../../../assets/images/exchangeGames/theTrap/theTrapK.svg'

const Trap = ({
  betPlacing,
  actions,
  exposure,
  headerHeight,
  exchangeGames,
  auth,
  sportEvent,
  elementsParams
 }) => {
  const dataOdds = sportEvent.sportData;

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  // get exposure
  const exposurePnl = (marketIndex, runnerIndex) => {
    if(auth) {
      const marketId = sportEvent?.sportData[marketIndex]?.id
      const runnerId = sportEvent?.sportData[marketIndex] &&
        sportEvent.sportData[marketIndex].runners[runnerIndex] &&
        sportEvent.sportData[marketIndex].runners[runnerIndex].id;
      let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(marketId + '-' + runnerId))]?.pnl;
      if (objFinder) {
        return ( <div
          className={ objFinder > 0
            ? "exposure-box-trap exposure-trap--pos"
            : "exposure-box-trap exposure-trap--neg"
          }
        >
          {objFinder}
        </div>);
      }
    } else return
  };
  // end get exposure


  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      await actions
            .requestExchangeGames('67680')
            .catch((e) => console.log('requestInplayPopular error = ', e))

    }, GET_INPLAY_POPULAR_INTERVAL)

      return () => clearIntervalAsync(timer)

  }, [actions, GET_INPLAY_POPULAR_INTERVAL])

  useEffect(() => {
    actions
      .requestSportStart("67680")
      .catch((e) => console.log('requestSportStart error = ', e))
  }, [dataOdds[1]?.status, dataOdds[2]?.status, actions])

  const resultStatus = (marketIndex, runner) => {
      let name;
      if (marketIndex === 0) name = "Match Odds";
      if (marketIndex === 1) name = "High/Low";
      if (marketIndex === 2) name = "Picture Card";
      const market = exchangeGames && exchangeGames.filter(item => item.marketHeader === name).slice(-1)[0];
      if (market?.marketRunner[runner]?.status === "WINNER") {
        return "winner";
      }
    return !dataOdds[marketIndex]?.isBettable ? "lock-ui" : "";
  };
  // end lock market

  // const renderBetPlacing = (runner) => {
  //   if (betPlacing.runner && width < 1024 && betPlacing.price !== undefined) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }

  // Bet placing
  const select = (marketIndex, runnerIndex, isBack) => {
    if (!dataOdds[marketIndex].isBettable) return
    const dataOdd = dataOdds[marketIndex];
    const item = dataOdds[marketIndex]?.runners[runnerIndex];

    const runner = {name: item.name, id: item.id};
    const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
    const marketId = dataOdd && dataOdd.id;
    const runnerId = oddData && oddData.runner && oddData.runner.id;
    if (!marketId || !runnerId) return;
    actions.setMarket(marketId);
    actions.setRunner(runnerId);
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
    actions.setIsBack(isBack);
    actions.setPrice(oddData && oddData.price);
    actions.setPriceActual(oddData && oddData.price);
    actions.setDelay(dataOdd && dataOdd.betDelay);
    actions.setMtype(dataOdd && dataOdd.mtype);
    actions.setBtype(dataOdd && dataOdd.btype);
    actions.setLine(oddData && oddData.line);
    actions.setFromOneClick(false);
    actions.setStake(null);

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log("err = ", err));
  };
  // End Bet placing

  return (
    <>
      <CardsInfo />
        {width < 1024 ?
        <div className='trapWrapper'>

          <div class="trap-table">
              {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
                <div class="trap-table__item">
                    <div class="trap-table__item-header">
                        Player A
                        <div class="exposure-box-modern">
                            <div class="exposure-box-modern">
                              {exposurePnl(0, 0)}
                            </div>
                        </div>
                    </div>
                    <div class="trap-table__item-bets-wrapper">
                        <div
                        style={{border: "2px solid #72bbef"}}
                        className={`trap-table__item-bet-cell bet-back ${resultStatus(0, 0)}`}
                        onClick={() => select(0, 0, true)}>
                            <span class="trap-table__item-bet-cell-price">
                              {dataOdds[0]?.runners[0]?.back[0]?.price || 0}
                            </span>
                        </div>
                        <div
                        style={{border: "2px solid #ee6a9c"}}
                        className={`trap-table__item-bet-cell bet-lay ${resultStatus(0, 0)}`}
                        onClick={() => select(0, 0, false)}>
                            <span class="trap-table__item-bet-cell-price">
                              {dataOdds[0]?.runners[0]?.lay[0]?.price || 0}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="trap-table__item">
                    <div class="trap-table__item-header">
                        Player B
                        <div class="exposure-box-modern">
                          <div class="exposure-box-modern">
                          {exposurePnl(0, 1)}
                          </div>
                        </div>
                    </div>
                    <div class="trap-table__item-bets-wrapper">
                        <div
                        style={{border: "2px solid #72bbef"}}
                        className={`trap-table__item-bet-cell bet-back ${resultStatus(0, 1)}`}
                        onClick={() => select(0, 1 , true)}
                        >
                            <span class="trap-table__item-bet-cell-price">
                              {dataOdds[0]?.runners[1]?.back[0]?.price || 0}
                            </span>
                        </div>
                        <div
                        style={{border: "2px solid #ee6a9c"}}
                        className={`trap-table__item-bet-cell bet-lay ${resultStatus(0, 1)}`}
                        onClick={() => select(0, 1 , false)}
                        >
                            <span class="trap-table__item-bet-cell-price">
                              {dataOdds[0]?.runners[1]?.lay[0]?.price || 0}
                            </span>
                        </div>
                    </div>
                </div>

                <div class='trap-table__seven-row'>

                    <div
                      className={`trap-table__seven-row-item ${resultStatus(1, 1)}`}
                      onClick={() => select(1, 1 , true)}
                    >
                        <span class="trap-table__seven-row-item-odd">
                          {dataOdds[1]?.runners[1]?.back[0]?.price || 0}
                        </span>
                        <div class="trap-table__seven-row-item-title">LOW</div>

                        {exposurePnl(1, 1)}

                    </div>
                    <div class="trap-table__seven-row-center"></div>
                    <div
                      className={`trap-table__seven-row-item ${resultStatus(1, 0)}`}
                      onClick={() => select(1, 0, true)}
                    >
                    <span class="trap-table__seven-row-item-odd">
                      {dataOdds[1]?.runners[0]?.back[0]?.price || 0}
                    </span>
                    <div class="trap-table__seven-row-item-title">HIGH</div>

                            <div>
                            {exposurePnl(1, 0)}
                            </div>

                    </div>
                    <div class="seven">
                        <span>7</span>
                    </div>

                </div>

                <div class="trap-table__picture">
                    <div class="trap-table__picture-cards">
                        <img src={TheTrapJ} />
                        <img src={TheTrapQ} />
                        <img src={TheTrapK} />
                    </div>
                    <div class='trap-table__picture-bets-wrapper'>
                        <div
                          className={`trap-table__picture-bet-cell bet-back ${resultStatus(2, 0)}`}
                          onClick={() => select(2, 0 , true)}
                        >
                            <span class="trap-table__picture-bet-cell-price">
                              {dataOdds[2]?.runners[0]?.back[0]?.price || 0}
                            </span>
                        </div>

                        <div
                             className={`trap-table__picture-bet-cell bet-lay ${resultStatus(2, 0)}`}
                             onClick={() => select(2, 0 , false)}
                        >
                            <span class="trap-table__picture-bet-cell-price">
                              {dataOdds[2]?.runners[0]?.lay[0]?.price || 0}
                            </span>
                        </div>
                        {exposurePnl(2, 0)}
                </div>
            </div>
            </div>
              <LastResults/>
          </div>
          :
          <div className='trapWrapperDesk'>
            <div className='trap-tableDesk'>
              <div className='trap-tableDesk__row'>

                <div className='trap-tableDesk__row-item'>
                  <div>
                    <div className='trap-tableDesk__row-item-title'>Player A</div>
                    {exposurePnl(0, 0)}
                  </div>
                  <div className='trap-tableDesk__row-item-odds'>
                    <div
                    className={`trap-tableDesk__row-item-odds-back ${resultStatus(0, 0)}`}
                    onClick={() => select(0, 0, true)}>
                      <span className='trap-tableDesk__row-item-odds-back-price'>
                        {dataOdds[0]?.runners[0]?.back[0]?.price || 0}
                      </span>
                    </div>
                    <div
                     className={`trap-tableDesk__row-item-odds-lay ${resultStatus(0, 0)}`}
                     onClick={() => select(0, 0, false)}>
                      <span className='trap-tableDesk__row-item-odds-lay-price'>
                        {dataOdds[0]?.runners[0]?.lay[0]?.price || 0}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='trap-tableDesk__row-item'>
                 <div>
                    <div className='trap-tableDesk__row-item-title'>Player B</div>
                   {exposurePnl(0, 1)}
                  </div>
                  <div className='trap-tableDesk__row-item-odds'>
                    <div
                      className={`trap-tableDesk__row-item-odds-back ${resultStatus(0, 1)}`}
                      onClick={() => select(0, 1, true)}>
                      <span className='trap-tableDesk__row-item-odds-back-price'>
                        {dataOdds[0]?.runners[1]?.back[0]?.price || 0}
                      </span>
                    </div>
                    <div className={`trap-tableDesk__row-item-odds-lay ${resultStatus(0, 1)}`}
                         onClick={() => select(0, 1, false)}>
                      <span className='trap-tableDesk__row-item-odds-lay-price'>
                        {dataOdds[0]?.runners[1]?.lay[0]?.price || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='trap-tableDesk__row'>
                <div class="trap-table desk">
                  <div class='trap-table__seven-row'>
                    <div
                      className={`trap-table__seven-row-item ${resultStatus(1, 1)}`}
                      onClick={() => select(1, 1, true)}>
                        <span class="trap-table__seven-row-item-odd">
                          {dataOdds[1]?.runners[1]?.back[0]?.price || 0}
                        </span>
                        <div class="trap-table__seven-row-item-title">LOW</div>
                        <div>
                          {exposurePnl(1, 1)}
                        </div>
                    </div>
                    <div class="trap-table__seven-row-center"></div>
                    <div
                      className={`trap-table__seven-row-item ${resultStatus(1, 0)}`}
                      onClick={() => select(1, 0, true)}>
                    <span class="trap-table__seven-row-item-odd">
                      {dataOdds[1]?.runners[0]?.back[0]?.price || 0}
                    </span>
                    <div class="trap-table__seven-row-item-title">HIGH</div>
                        <div>
                          {exposurePnl(1, 0)}
                        </div>
                    </div>
                    <div class="seven">
                        <span>7</span>
                    </div>
                  </div>
                  <div class="trap-table__picture">
                    <div class="trap-table__picture-cards">
                        <img src={TheTrapJ} />
                        <img src={TheTrapQ} />
                        <img src={TheTrapK} />
                    </div>
                    <div class='trap-table__picture-bets-wrapper'>
                        <div className={`trap-table__picture-bet-cell bet-back ${resultStatus(2, 0)}`}
                        onClick={() => select(2, 0, true)}>
                            <span class="trap-table__picture-bet-cell-price">
                              {dataOdds[2]?.runners[0]?.back[0]?.price || 0}
                            </span>
                        </div>
                        <div className={`trap-table__picture-bet-cell bet-lay ${resultStatus(2, 0)}`}
                             onClick={() => select(2, 0, false)}
                        >
                            <span class="trap-table__picture-bet-cell-price">
                              {dataOdds[2]?.runners[0]?.lay[0]?.price || 0}
                            </span>
                        </div>
                      {exposurePnl(2, 0)}
                    </div>
                </div>
                </div>
              </div>
            </div>
            <LastResults/>
          </div>}
      </>
    )
}
export default connectTrap()(Trap)
