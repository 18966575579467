import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import {
  getSportEventSuccess,
  getFancySuccess,
  getSportStatsSuccess,
  getSportLadderSuccess,
  getSportLadderError,
  getSportVideoSuccess,
  getSportVisualisationSuccess,
  getSportEventMarketsSuccess,
  getSportLimitsSuccess,
  getSportLimitsError,
} from './sportEvent.slice'
import { BASE_DEV_URL } from '../../config/api.config'
import axios from 'axios'

export const requestSportData =
  (eventTypeId, eventId, history) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    let data = { match_id: eventId }
    if (checkAuth) {
      try {
        const response = await httpAuth.post(`match/matchDetails`, {
          ...data,
          combine: true,
        })
        if (response.data.status) {
          if (!response?.data?.data || !response?.data?.data?.length) {
            history.push('/default-page')
            return
          }
          dispatch(getSportEventSuccess(response.data.data))
        }
        return response
      } catch (e) {
        // dispatch(getSportEventError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    } else {
      try {
        const response = await httpNotAuth.post(`match/matchDetailsOpen`, data)
        if (response.data.status) {
          if (!response?.data?.data || !response?.data?.data?.length) {
            history.push('/default-page')

            return
          }
          dispatch(getSportEventSuccess(response.data.data))
        }
        return response
      } catch (e) {
        // dispatch(getSportEventError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    }
  }
export const requestSportDataFancy =
  (eventTypeId, eventId, history) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if (checkAuth) {
      let data = {
        match_id: eventId,
        combine: true,
      }
      try {
        const response = await httpAuth.post(`fancy/getFancies`, data)
        if (response.data.status) {
          if (!response?.data?.data || !response?.data?.data?.length) {
            dispatch(getFancySuccess(null))
            history.push('/default-page')
            return
          }
          if (response?.data?.data?.length <= 0) {
            dispatch(getFancySuccess(null))
          } else {
            dispatch(getFancySuccess(response.data))
          }
        }
        return response
      } catch (e) {
        // dispatch(getSportEventError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    } else {
      let data = { match_id: eventId }
      try {
        const response = await httpNotAuth.post(`fancy/fancies`, data)
        if (response.data.status) {
          if (!response?.data?.data || !response?.data?.data?.length) {
            dispatch(getFancySuccess(null))
            history.push('/default-page')

            return
          }
          if (response?.data?.data?.length <= 0) {
            dispatch(getFancySuccess(null))
          } else {
            dispatch(getFancySuccess(response.data))
          }
        }
        return response
      } catch (e) {
        // dispatch(getSportEventError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    }
  }

export const requestSportDataMarkets =
  (eventId, id) => async (dispatch, getState) => {
    const checkAuth = getState().auth

    if (checkAuth) {
      try {
        const response = await httpAuth.get(
          `exchange/odds/market/${eventId}/${id}`
        )
        if (response.data.success) {
          dispatch(getSportEventMarketsSuccess(response.data.result))
        }
        return response
      } catch (e) {
        // dispatch(getSportEventMarketsError())
      }
    } else {
      try {
        const response = await httpNotAuth.get(
          `/exchange/odds/market/${eventId}/${id}`
        )
        if (response.data.success) {
          dispatch(getSportEventMarketsSuccess(response.data.result))
        }
        return response
      } catch (e) {
        // dispatch(getSportEventMarketsError())
      }
    }
  }

export const requestSportStats =
  (eventTypeId, eventId, history) => async (dispatch, getState) => {
    const checkAuth = getState().auth

    if (checkAuth) {
      try {
        const response = await httpAuth.get(
          `/match-center/stats/${eventTypeId}/${eventId}`
        )
        if (response.data.success) {
          dispatch(
            getSportStatsSuccess({
              ...response.data.result,
              eventTypeId,
              eventId,
            })
          )
        }
        return response
      } catch (e) {
        // dispatch(getSportStatsError())
      }
    } else {
      try {
        const response = await httpNotAuth.get(
          `/match-center/stats/${eventTypeId}/${eventId}`
        )
        if (response.data.success) {
          dispatch(
            getSportStatsSuccess({
              ...response.data.result,
              eventTypeId,
              eventId,
            })
          )
        }
        return response
      } catch (e) {
        // dispatch(getSportStatsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    }
  }

export const requestSportLadder = (id) => async (dispatch, getState) => {
  const userId = getState().auth
  let data = {
    fancy_id: id,
  }
  if (userId) {
    try {
      const response = await httpAuth.post(
        `fancy/getRunTimeFancyPosition`,
        data
      )
      if (response.data.status) {
        dispatch(getSportLadderSuccess(response.data.data))
      }
      return response
    } catch (e) {
      dispatch(getSportLadderError())
    }
  }
}

export const requestSportLimits =
  (eventTypeId, eventId) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    let data = {
      match_id: eventId,
      sport_id: eventTypeId,
    }
    if (checkAuth) {
      try {
        const response = await httpAuth.post(`event/getEventsLimit`, data)
        if (response.data.status) {
          // if(!response?.data?.data || !response?.data?.data?.length){
          //     history.push("/default-page")
          //     return
          // }
          console.log('limits response', response.data)
          dispatch(getSportLimitsSuccess(response.data))
        }
        return response
      } catch (e) {
        dispatch(getSportLimitsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    } else {
      try {
        const response = await httpNotAuth.post(`event/getEventsLimit`, data)
        if (response.data.status) {
          // if(!response?.data?.data || !response?.data?.data?.length){
          //     history.push("/default-page")

          //     return
          // }
          dispatch(getSportLimitsSuccess(response.data.data))
        }
        return response
      } catch (e) {
        dispatch(getSportLimitsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      }
    }
  }

// 98788, 98792 - roulette
// 67610 - trio
// 67620 - queen
// 67630 - teenpatti test
// 98566 - sicbo
// 67680 - trap
// 67660 - 2 card teenpatti
// 67600 - muflis
// 56767 - Live Teenpatti
// 92038 - Baccarat
// 67570 - Bollywood Casino
// 67575 - Casino-meter
// 56768 - Teenpatti T20
// 56968 ,  56969 - Hi Low
// 56966, 56967 - 32 Card Casino
// 87564, 87565 - Andar Bahar
// 98791, 98795 - AmarAkbarAnthony
// 67563, 67564 - Poker
// 67567 - Poker 20-20
// 90100, 90101 - Race 2020
// 92036, 92037 - Matka
// 98789, 98793 - 7upDown
// 98790, 98794 - Dragon Tiger
// 67565, 67566 - Six Player Poker

const preparedGamesIds = [
  '98788',
  '98792',
  '67610',
  '67620',
  '67630',
  '98566',
  '67680',
  '67660',
  '67600',
  '56767',
  '92038',
  '67570',
  '67575',
  '56768',
  '56968',
  '56969',
  '56966',
  '56967',
  '87564',
  '87565',
  '98791',
  '98795',
  '67563',
  '67564',
  '67567',
  '90100',
  '90101',
  '92036',
  '92037',
  '98789',
  '98793',
  '98790',
  '98794',
  '67565',
  '67566',
  '67580',
]
const virtualsGamesId = [
  '98792',
  '56966',
  '92036',
  '98793',
  '56766',
  '56769',
  '56969',
  '67563',
  '87565',
  '92036',
  '98795',
  '67566',
  '98794',
]

export const requestSportVideo = (eventTypeId, eventId) => async (dispatch) => {
  let data = { match_id: eventId }
  try {
    const response = await httpAuth.post(`/match/getTvUrlScoreboardUrl`, data)
    if (response.data.status) {
      // if (!response.data.result.config.videoId.includes("open.gemexch.bet")){
      //     const virtualUrl = "https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/wowstream/";
      //     response.data.result.config.videoId = virtualUrl + response.data.result.config.videoId.split('/').slice(-1).toString()
      // }

      dispatch(getSportVideoSuccess(response.data.data))
    }
    return response
  } catch (e) {
    // dispatch(getSportVideoError())
  }
}

export const requestSportVisualisation =
  (eventTypeId, eventId) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if (checkAuth) {
      if (
        preparedGamesIds.includes(eventId) &&
        !virtualsGamesId.includes(eventId)
      ) {
        try {
          const response = await httpAuth.post(
            `/match-center/stats/d-access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            if (!response.data.result.url.includes('open.gemexch.bet')) {
              const virtualUrl =
                'https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/stream/'
              let data = {
                ...response.data.result,
                url:
                  virtualUrl +
                  response.data.result.url.split('/').slice(-1).toString(),
              }
              dispatch(getSportVisualisationSuccess(data))
            } else {
              let data = {
                ...response.data.result,
                url: response.data.result.url,
              }
              dispatch(getSportVisualisationSuccess(data))
            }
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      } else if (virtualsGamesId.includes(eventId)) {
        try {
          const response = await httpAuth.post(
            `/match-center/stats/d-access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            if (!response.data.result.url.includes('open.gemexch.bet')) {
              const virtualUrl =
                'https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/stream/'
              let data = {
                ...response.data.result,
                url:
                  virtualUrl +
                  response.data.result.url.split('/').slice(-1).toString(),
              }
              dispatch(getSportVisualisationSuccess(data))
            } else {
              let data = {
                ...response.data.result,
                url: response.data.result.url,
              }
              dispatch(getSportVisualisationSuccess(data))
            }
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      } else {
        try {
          const response = await httpAuth.post(
            `/match-center/stats/access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            dispatch(getSportVisualisationSuccess(response.data.result))
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      }
    } else {
      if (
        preparedGamesIds.includes(eventId) &&
        !virtualsGamesId.includes(eventId)
      ) {
        try {
          const response = await httpNotAuth.post(
            `/match-center/stats/d-access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            if (!response.data.result.url.includes('open.gemexch.bet')) {
              const virtualUrl =
                'https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/stream/'
              let data = {
                ...response.data.result,
                url:
                  virtualUrl +
                  response.data.result.url.split('/').slice(-1).toString(),
              }
              dispatch(getSportVisualisationSuccess(data))
            } else {
              let data = {
                ...response.data.result,
                url: response.data.result.url,
              }
              dispatch(getSportVisualisationSuccess(data))
            }
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      } else if (virtualsGamesId.includes(eventId)) {
        try {
          const response = await httpNotAuth.post(
            `/match-center/stats/d-access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            if (!response.data.result.url.includes('open.gemexch.bet')) {
              const virtualUrl =
                'https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/stream/'
              let data = {
                ...response.data.result,
                url:
                  virtualUrl +
                  response.data.result.url.split('/').slice(-1).toString(),
              }
              dispatch(getSportVisualisationSuccess(data))
            } else {
              let data = {
                ...response.data.result,
                url: response.data.result.url,
              }
              dispatch(getSportVisualisationSuccess(data))
            }
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      } else {
        try {
          const response = await axios.post(
            BASE_DEV_URL + `/open/match-center/stats/access-token`,
            { eventTypeId, eventId }
          )
          if (response.data.success) {
            dispatch(getSportVisualisationSuccess(response.data.result))
          }
          return response
        } catch (e) {
          // dispatch(getSportVisualisationError())
        }
      }
    }
  }
