import './LastResultPopUp.scss'
import React from 'react'
import CloseCross from '../../../../assets/images/closeCross.svg'
import RouletteLastResult from '../../Roulette/RouletteHelpers/RouletteLastResult/RouletteLastResult'
import TrioLastResult from '../../Trio/TrioHelpers/TrioLastResultPopUp/TrioLastResult'
import TwoCardTPLastResult from '../../TwoCardTeenPatti/TwoCardHelpers/TwoCardTPLastResult/TwoCardTPLastResult'
import QueenLastResult from '../../Queen/QueenHelpers/QueenLastResult/QueenLastResult'
import TeenpattiTestLastResult from '../../TeenpattiTest/TeenpattiTestHelpers/TeenpattiTestLastResultPopUp/TeenpattiTestLastResult'
import TrapLastResult from '../../Trap/TrapHelpers/TrapLastResultPopUp/TrapLastResult'
import SicboLastResult from '../../Sicbo/SicboHelpers/SicboLastResult/SicboLastResult'
import MuflisTeenpattiLastResult from '../../MuflisTeenpatti/MuflisTeenpattiHelpers/MuflisTeenpattiLastResultPopUp/MuflisTeenpattiLastResult'
import Poker20LastResult from '../../Poker20/Poker20Helpers/Poker20LastResult/Poker20LastResult'
import LiveTeenpattiLastResult from '../../LiveTeenpatti/LiveTeenpattiHelpers/LiveTeenpattiLastResultPopUp/LiveTeenpattiLastResult'
import TeenpattiT20LastResult
  from '../../TeenpattiT20/TeenpattiT20Helpers/TeenpattiT20LastResult/TeenpattiT20LastResult'
import BaccaratLastResult from '../../Baccarat/BaccaratHelpers/BaccaratLastResultPopUp/BaccaratLastResult'
import BollywoodCasinoLastResult from '../../BollywoodCasino/BollywoodCasinoHelpers/BollywoodCasinoLastResultPopUp/BollywoodCasinoLastResult'
import Race20LastResult from '../../Race20/Race20Helpers/Race20LastResult/Race20LastResult'
import CasinoMeterLastResult from '../../CasinoMeter/CasinoMeterHelpers/CasinoMeterLastResult/CasinoMeterLastResult'
import PokerLastResult from '../../Poker/PokerHelpers/PokerLastResult/PokerLastResult'
import HiLowLastResult from '../../HiLow/HiLowHelpers/HiLowLastResultPopUp/HiLowLastResult'
import AndarBaharLastResult from '../../AndarBahar/AndarBaharHelpers/AndarBaharLastResultPopUp/AndarBaharLastResult'
import SevenUpDownLastResult from '../../7UpDown/SevenUpDownHelpers/SevenUpDownLastResultPopUp/SevenUpDownLastResult'
import DragonTigerLastResult from '../../DragonTiger/DragonTigerHelpers/DragonTigerLastResult/DragonTigerLastResult'
import MatkaLastResult from '../../Matka/MatkaHelpers/MatkaLastResultPopUp/MatkaLastResult'
import AmarAkbarAnthonyLastResult from '../../AmarAkbarAnthony/AmarAkbarAnthonyHelpers/AmarAkbarAnthonyLastResultPopUp/AmarAkbarAnthonyLastResult'
import CasinoWarLastResult from '../../CasinoWar/CasinoWarHelpers/CasinoWartLastResultPopUp/CasinoWarLastResult'
import SixPlayerPokerLastResult from '../../SixPlayerPoker/SixPlayerPokerHelpers/SixPlayerPokerLastResult/SixPlayerPokerLastResult'
import { useSelector } from 'react-redux'

import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const LastResultPopUp = ({
  open,
  onDidDismiss,
  data,
  eventName,
  exchangeGames,
}) => {
  // let { eventId } = useParams()
  const eventId = data?.marketId?.gameId;

  const resultsCards = () => {
    // ***************************   Trio *****************************
    if (eventId === '67610') {
      return <TrioLastResult data={data} />
    }

    // ***************************   Roulette *****************************
    if ((eventId && eventId === '98788') || (eventId && eventId === '98792')) {
      return <RouletteLastResult data={data} />
    }

    // ***************************   Two Card Teen Patii *****************************
    if (eventId === '67660') {
      return <TwoCardTPLastResult data={data} />
    }

    // ***************************   Queen   *****************************
    if (eventId === '67620') {
      return <QueenLastResult data={data} />
    }

    // ***************************   TeenPatti Test   *****************************
    if (eventId === '67630') {
      return <TeenpattiTestLastResult data={data} />
    }

    // ***************************   The Trap   *****************************
    if (eventId === '67680') {
      return <TrapLastResult data={data} />
    }

    // ***************************   Sicbo   *****************************
    if (eventId === '98566') {
      return <SicboLastResult data={data} />
    }
    // ***************************   Teenpatti muflis   *****************************
    if (eventId === '67600') {
      return <MuflisTeenpattiLastResult data={data} />
    }
    // ***************************  Poker 20-20  *****************************
    if (eventId === '67567') {
      return <Poker20LastResult data={data} />
    }
    if (eventId === '56767' || eventId === "56766") {
      return <LiveTeenpattiLastResult data={data} />
    }
    // ***************************  Teenpatti T20  *****************************
    if ((eventId && eventId === '56768') || (eventId && eventId === '56769')) {
      return <TeenpattiT20LastResult data={data}/>
    }
    // ***************************  Baccarat  *****************************
    if (eventId === '92038') {
      return <BaccaratLastResult data={data} />
    }
    // ***************************  Bollywood Casino  *****************************
    if ((eventId === '67570') || (eventId === '67571')) {
      return <BollywoodCasinoLastResult data={data} />
    }
    // ***************************  Race 20  *****************************
    if ((eventId === '90100') || (eventId === '90101')) {
      return <Race20LastResult data={data} />
    }

    if ((eventId === '67575')) {
      return <CasinoMeterLastResult data={data} />
    }
    // ***************************  Poker  *****************************
    if ((eventId === '67563') || (eventId === '67564')) {
      return <PokerLastResult data={data} />
    }
    // ***************************  Hi Low  *****************************
    if ((eventId === '56968') || (eventId === '56969')) {
      return <HiLowLastResult data={data} />
    }
    // ***************************  Andar Bahar  *****************************
    if ((eventId === '87564') || (eventId === '87565')) {
      return <AndarBaharLastResult data={data} />
    }
    if (eventId && (eventId === '98789' || eventId === '98793')) {
      return <SevenUpDownLastResult data={data} />
    }

    // *************************** Dragon Tiger *****************************
    if ((eventId && eventId === '98790') || (eventId && eventId === '98794')) {
      return <DragonTigerLastResult data={data}/>
    }
    if ((eventId === '92036') || (eventId === '92037')) {
      return <MatkaLastResult data={data} />
    }
    // *************************** Amar Akbar Anthony *****************************
    if ((eventId && eventId === '98791') || (eventId && eventId === '98795')) {
      return <AmarAkbarAnthonyLastResult data={data} />
    }
    // *************************** Casino War *****************************
    if (eventId && eventId === '67580') {
      return <CasinoWarLastResult data={data} exchangeGames={exchangeGames} />
    }
    // *************************** SixPlayerPoker *****************************
    if ((eventId && eventId === '67565') || (eventId && eventId === '67566')) {
      return <SixPlayerPokerLastResult data={data} />
    }
  }

  const matchDate = (date) => {
    const newDate = new Date()
    date = newDate.toLocaleDateString('en-GB')
    return date
  }

  const matchTime = (time) => {
    const now = new Date(time)
    return (
      <span
        style={{display: 'inline-block', marginLeft: '5px'}}
        className="pop-upLastResult-content__header__matchTime__date">{now.toLocaleTimeString()}
      </span>
    )
  }

  // window width
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  // end window width

  // console.log('eventId_!!', eventId)


  return createPortal(
    <>
      {open && (
        <div className="editLastResultPopUp">
          <div
            className={`LastResultPopUp_wrapper`}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
              }
            }}
            // style={{height: (width >= 1024) ? LastResultPopUpWrapperHeight : '100%'}}
          >
            <div className={`pop-upLastResult ${eventName}`}>
              <div className="pop-upLastResult-content">
                <div className="pop-upLastResult-content__header">
                  {/*{ (eventId === '67566' && width < 500) ?*/}
                  {/*  <>*/}
                  {/*    <div className="pop-upLastResult-content__header__title">*/}
                  {/*      {eventName.replace("(Virtual)", "" )}*/}
                  {/*    </div>*/}
                  {/*    <div className="pop-upLastResult-content__header__title">*/}
                  {/*      (Virtual)*/}
                  {/*    </div>*/}
                  {/*  </>*/}
                  {/*  :*/}
                    <div className={`pop-upLastResult-content__header__title ${eventId === '67566' && width < 400 && "virtual"}`}>
                      {eventId === '98566' ? 'Super ' : ''}
                      { eventId === '67660' && "2 Card Teenpatti"}
                      { eventId !== '67660' && eventName}
                    </div>
                  <img
                    className="pop-upLastResult-content__header__close"
                    src={CloseCross}
                    alt=""
                    onClick={() => onDidDismiss(false)}
                  />
                  <div className="pop-upLastResult-content__header__gameId">
                    Round ID:&nbsp; <span className={`pop-upLastResult-content__gameId`}>{data && data?.marketId?.roundId}</span>
                  </div>
                  <div className='pop-upLastResult-content__header__matchTime'>
                    Match Time:
                    {/* <span className='pop-upLastResult-content__header__matchTime__date'>{data && data?.marketId?.createdAt.slice(0, 10)}</span> */}
                    <span className='pop-upLastResult-content__header__matchTime__date'> {matchDate(data && data?.marketId?.createdAt.slice(0, 10))}</span>
                    {matchTime(data && data?.marketId?.createdAt)}
                  </div>
                </div>
                <div className="pop-upLastResult-content__results">
                  {resultsCards()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>,
    modalRoot
  )
}


export default LastResultPopUp
