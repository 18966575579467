import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMessageError, setMessageSuccess } from './message.slice'

export const requestMessage = (memberCode) => async (dispatch) => {
  // if(memberCode) {
    //News msg here
    try {
      const response = await http.post(
        `news/getNews`
      )
      if (response.data.status) {
        console.log("news", response.data)
        dispatch(setMessageSuccess(response.data.data))
      }
      return response
    } catch (e) {
      dispatch(getMessageError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  // }
}
