import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setShowBetPlacingPopover, setBetPlacingPopoverStage, setIsCashOut, setCashOutId } from '../../store/betPlacing/betPlacing.slice'
import { requestSetOrder, requestCashOut } from '../../store/betPlacing/betPlacing.thunks'
import { requestOpenBets } from '../../store/openBets/openBets.thunks'

export const connectBetPlacingPopover = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      betPlacing: state.betPlacing,
      elementsParams: state.elementsParams,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setBetPlacingPopoverStage,
      setShowBetPlacingPopover,
      requestSetOrder,
      setIsCashOut,
      requestCashOut,
      setCashOutId,
      requestOpenBets,
      requestSetOrder,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
