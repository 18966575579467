import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { connectBetSlipDesktop } from './BetSlipDesktop.connect'
import DesktopBetPlacing from '../DesktopBetPlacing/DesktopBetPlacing'
import OpenBets from '../OpenBets/OpenBets'
import EditStakesPopUp from './EditStakesPopUp/EditStakesPopUp'

import './BetSlipDesktop.scss'

const BetSlipDesktop = ({
  betPlacing,
  actions,
  openBets,
  auth
}) => {
  const [isOpenBets, setIsOpenBets] = useState(false)
  const [isThisEvent, setIsThisEvent] = useState(
    localStorage.getItem('page')?.indexOf('event-page') < 0 ? false : true
  )
  const setInfoDispatch = useSelector((state) => state.openBetsList.detailed)
  const setThisEventDispatch = useSelector((state) => state.openBetsList.event)
  const isPopUpOpenDispatch = useSelector(
    (state) => state.editStakesPopUp.isOpen
  )

  useEffect(() => {
    if (!isThisEvent) {
      actions.setThisEvent(false)
    }
  }, [isOpenBets])

  useEffect(()=> {
    actions.requestOpenBets()
  }, [])

  useEffect(() => {
    setIsOpenBets(false)
  }, [betPlacing])

  useEffect(() => {
    if (!betPlacing.betPlacingPopoverStage2) {
      setIsOpenBets(true)

    }
  }, [betPlacing.betPlacingPopoverStage2])

  useEffect(() => {
    setIsThisEvent(localStorage.getItem('page')?.indexOf('event-page') < 0 ? false : true)
  }, [isOpenBets])
  let page = localStorage.getItem('page')
  useEffect(() => {
    if(page?.includes("event-page")) {
      actions.setThisEvent(true)}
  }, [page])

  return (
    <>
      <div className="betSlipDesktop">
        <div className="betSlipDesktop__topSection">
          <span className="betSlipDesktop__topSection-title">Betslip</span>
        </div>
        <div className="betSlipDesktop__betTop">
          <div style={{display: 'flex'}}>
          <div
            className={
              isOpenBets
                ? 'betSlipDesktop__betTop-slip'
                : 'betSlipDesktop__betTop-slip active'
            }
            onClick={() => setIsOpenBets(false)}
          >
            Betslip
          </div>
          <div
            className={
              !isOpenBets
                ? 'betSlipDesktop__betTop-openBets'
                : 'betSlipDesktop__betTop-openBets active'
            }
            onClick={() => setIsOpenBets(true)}
          >
            Open Bets
          </div>
          </div>
          <button
            type="button"
            onClick={() => {
              if(!auth) actions.setLoginNew(true)
              else actions.setIsOpen(!isPopUpOpenDispatch)
            }}
            className="betSlipDesktop__betTop-editStakesBtn"
          >Edit Stakes
          </button>
        </div>


        <div className="betSlipDesktop__betMain">
          {isOpenBets ? (
            <>
              <div className="betSlipDesktop__topFilters">
                <div
                  className="betSlipDesktop__topFilters-item"
                  onClick={() => {
                    // setIsBetInfo(!isBetInfo);
                    // console.log(isBetInfo);
                    // dispatch(setInfoDetailed(!setInfoDispatch))
                    actions.setInfoDetailed(!setInfoDispatch)
                  }}
                >
                  <div
                    className={
                      !setInfoDispatch
                        ? 'betSlipDesktop__topFilters-item-check'
                        : 'betSlipDesktop__topFilters-item-check checked'
                    }
                  ></div>
                  <span className="betSlipDesktop__topFilters-item-title">
                    Show bet Info
                  </span>
                </div>
                  <div
                    className="betSlipDesktop__topFilters-item"
                    onClick={() => actions.setThisEvent(!setThisEventDispatch)}
                  >
                    <div
                      className={
                        !setThisEventDispatch
                          ? 'betSlipDesktop__topFilters-item-check'
                          : 'betSlipDesktop__topFilters-item-check checked'
                      }
                    ></div>
                    <span className="betSlipDesktop__topFilters-item-title">
                      Show bets for this event
                    </span>
                  </div>
              </div>
              <OpenBets openBets={openBets} isEvent={setThisEventDispatch} redirect={true} />
            </>
          ) : (
            <>
            {betPlacing.showBetPlacingPopover && <div className='betSlipDesktop__betMain-shadow'></div>}
            <DesktopBetPlacing />
            </>
          )}
        </div>
      </div>
      {/* <EditStakesPopUp open={isPopUpOpenDispatch} /> */}
    </>
  )
}

export default connectBetSlipDesktop()(BetSlipDesktop)
