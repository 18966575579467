import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import {
  useIonViewWillEnter,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonImg,
} from '@ionic/react'
import './EventPage.scss'
import { connectEventPage } from './eventpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Timeline } from 'react-twitter-widgets'
import RaceListEventDetails from '../../components/RaceListEventDetails'
import SportEvent from '../../components/SportEvent/SportEvent'
import LiveSection from '../../components/LiveSection/LiveSection'
import OpenBets from '../../components/OpenBets/OpenBets'
import { RaceEventDropdownList } from '../../components/RaceEventDropdown'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import {
  GET_STATS_INTERVAL,
  GET_MARKET_DATA_INTERVAL,
} from '../../config/app.config'
import { getSportEventSuccess, getFancySuccess, getSportVideoSuccess } from '../../store/sportEvent/sportEvent.slice'
import broadcastImg from '../../assets/images/inplay-logo-iconSport.svg'
import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import UpcomingRacesAccordion from '../../components/UpcomingRacesAccordion/UpcomingRacesAccordion'
import { formatTime } from '../../helpers/date.helper'

const EventPage = ({
  actions,
  lastActivityDate,
  twitterSettings,
  sportEventStats,
  openBets,
  orders,
  sportData,
  sportDataFancy,
  sportVideo,
  rulesPopUp,
  auth,
  elementsParams
}) => {

  const history = useHistory()
  const [event, setEvent] = useState()
  const dispatch = useDispatch()
  const { eventTypeId, eventId } = useParams()
  const [activeTab, setActiveTab] = useState(0)
  const [contentScrolled, setContentScrolled] = useState(false)
  const [style, setStyle] = useState({})
  const [racingEventHeader, setRacingEventHeader] = useState(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(1)
  const [upcomingRaces, setUpcomingRaces] = useState(null)
  //window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  //end window width
  useEffect(() => {
    // Load event data from localStorage
    const storedEvent = localStorage.getItem("Event");
    if (storedEvent) {
      setEvent(JSON.parse(storedEvent));
    }

    // Cleanup function to remove data on component unmount
    return () => {
      localStorage.removeItem("Event");
    };
  }, []);

  const onScroll = (e) => {
    if (e.detail.deltaY < 0 || e.detail.scrollTop <= 120) {
      if (contentScrolled) setContentScrolled(false)
    } else if (e.detail.deltaY > 0) {
      if (!contentScrolled && e.detail.scrollTop > 120) setContentScrolled(true)
    }
  }

  const isRacing = eventTypeId == 7 || eventTypeId == 4339
  const isLoaded = isRacing
    ? true
    : sportData?.length ? true : false
    // : ((sportData?.[0]?.event?.id === eventId &&
    //     sportData?.[0]?.eventTypeId === eventTypeId) ||
    //     sportData?.[0]?.groupById === eventId) 
      // sportEventStats?.eventId === eventId &&
      // sportEventStats?.eventTypeId === eventTypeId
  const sportTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    //here is tabs movile for live
    sportVideo &&
    (sportVideo.tv_url || sportVideo.match_scoreboard_url)
      ? {
          label: 'live',
          icon: <span className="LiveSection__tv-icon"></span>,
          setTab: () => setActiveTab(2),
        }
      : undefined,
    {
      label: `open bets (${(openBets && openBets?.length) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]
  const raceTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    undefined,
    {
      label: `open bets (${(openBets && openBets?.length) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/event-page/${eventTypeId}/${eventId}`
      ) !== -1
    )
      actions.requestSportStart(eventId, isRacing)
      if(history.location.pathname.includes("/my-open-bets")) {

      } else 
      actions.requestOpenBets(eventId, eventTypeId)
    if (
      history.location.pathname.indexOf(
        `/event-page/${eventTypeId}/${eventId}`
      ) !== -1 &&
      activeTab == 3
    )
      actions.requestOpenBets(eventId, eventTypeId)
  }, [
    actions,
    activeTab,
    eventId,
    eventTypeId,
    history.location.pathname,
    lastActivityDate,
  ])

  useEffect(()=> {
    actions.requestSportStart(eventId, isRacing)
  }, [openBets])

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/event-page/${eventTypeId}/${eventId}`
      ) !== -1 && auth
    ) {
      setActiveTab(2)
    } else {
      if (sportEventStats?.scorecard || sportEventStats?.isBRScore) {
      setActiveTab(2)
    } else {
      setActiveTab(2)
    }
    }
  }, [eventTypeId, eventId, history.location.pathname, auth])

  useEffect(() => {
    if (
      (isRacing ||
        (sportVideo &&
          !sportVideo.tv_url &&
          !sportVideo.match_scoreboard_url)) &&
      activeTab === 2
    )
      setActiveTab(0)
  }, [sportEventStats, activeTab, eventTypeId, isRacing])

  useEffect(() => {
    if (activeTab == 1) actions.requestTwitterSettings()
    if (activeTab == 3) actions.requestOpenBets(eventId, eventTypeId)
  }, [actions, activeTab, eventId])

  /**
   * sport event
   */
  useEffect(() => {
    actions.requestSportData(eventTypeId, eventId);
    actions.requestSportDataFancy(eventTypeId, eventId);
    auth && actions.requestSportVideo(eventTypeId, eventId);
    auth && actions.requestSportLimits(eventTypeId, eventId)
    const mainTimer = setIntervalAsync(async () => {
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {
        if (!isRacing) {
          await actions.requestSportData(eventTypeId, eventId)
          await actions.requestSportDataFancy(eventTypeId, eventId);    
        }
      }
    }, GET_MARKET_DATA_INTERVAL)

    const betsTimer = setIntervalAsync(async () => {
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {
        if (!isRacing) {
          await actions.requestOpenBets(eventId, eventTypeId)  
        }
      }
    }, 10000)

    return () => {
      clearIntervalAsync(mainTimer)
      clearIntervalAsync(betsTimer)
      dispatch(getFancySuccess(null))
      dispatch(getSportEventSuccess(null))
      dispatch(getSportVideoSuccess(null))
    }
  }, [auth, eventTypeId, eventId, history.location.pathname, isRacing, actions])

  // useEffect(() => {
  //   if (!isRacing) {
  //     if (
  //       history.location.pathname.indexOf(
  //         `/event-page/${eventTypeId}/${eventId}`
  //       ) !== -1
  //     ) {
  //       actions.requestSportStats(eventTypeId, eventId)
  //     }
  //     const mainTimer = setIntervalAsync(async () => {
  //       if (
  //         history.location.pathname.indexOf(
  //           `/event-page/${eventTypeId}/${eventId}`
  //         ) !== -1
  //       ) {
  //         await actions.requestSportStats(eventTypeId, eventId)
  //       }
  //     }, GET_STATS_INTERVAL)
  //     return () => clearIntervalAsync(mainTimer)
  //   }
  // }, [eventTypeId, eventId, history.location.pathname, isRacing, actions])

  useIonViewWillEnter(async () => {
    actions.getSportEventError()
    actions.getSportStatsError()
    if (!isRacing) {
      await Promise.all([
        actions.requestSportData(eventTypeId, eventId),
        actions.requestSportDataFancy(eventTypeId, eventId),  
        auth && actions.requestSportVideo(eventTypeId, eventId),
        actions.requestSportLimits(eventTypeId, eventId) 
      ])
    }
  })
  /**
   *
   */

  // request upcoming races api
  const raceDataRequest = async (Id) => {
    if (!Id) {
      Id = sessionStorage.getItem('navigationID')
    }
    if (auth) {
      try {
        const response = await httpAuth.get(`/exchange/odds/topRaces/${Id}`)
        if (response.data.success) {
          setUpcomingRaces(response.data.result)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const response = await httpNotAuth.get(`/exchange/odds/topRaces/${Id}`)
        if (response.data.success) {
          setUpcomingRaces(response.data.result)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  useEffect(() => {
    if (width > 1024 && isRacing) {
      // raceDataRequest(eventTypeId)
    }
  }, [eventTypeId, isRacing, width])
  // end request upcoming races api

  const renderPageContent = (e) => {
    if (isLoaded) {
      //TODO: list on eventPage for upcoming racing, temporary comented
      if (isRacing)
        return (
          <div className="market eventPage-racing">
            <RaceEventDropdownList
              isRacing={isRacing}
              oddId={eventId}
              isOpen={true}
              full
              showPageHeader={true}
              setRacingEventHeader={setRacingEventHeader}
              showJockeyName={true}
              showWinner={true}
            />
            {/* <IonLabel className="EventPage__title">Upcoming races</IonLabel>
            {width < 1024 ? (
              <RaceListEventDetails
                exclude={eventId}
                eventsShow={5}
                eventTypeId={eventTypeId}
                showJockeyName={true}
              />
            ) : (
              upcomingRaces &&
              upcomingRaces.map((item, index) => {
                return (
                  <UpcomingRacesAccordion
                    key={item?.id + index}
                    item={item}
                    index={index}
                    isAccordionOpen={isAccordionOpen}
                    setIsAccordionOpen={setIsAccordionOpen}
                    showHeader={false}
                    showJockeyName={true}
                  />
                )
              })
            )} */}
          </div>
        )
      return <SportEvent eventTypeId={eventTypeId} eventId={eventId} />
    }
    return (
      <>
        <LoaderContent />
      </>
    )
  }

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${formatTime(
      date.getMinutes()
    )}:${formatTime(date.getSeconds())}`
  }
  const startTime = event?.match_date || event?.market_start_time
  const startDate = new Date(startTime)
  const optimizedDate = tsToDate(startDate, timeZoneName)
  const hasVideo = event && event?.inplay

  //// score logic

  const eventStats = () => {
    if (sportEventStats?.stats?.battingTeam) {
      const bt = sportEventStats.stats.battingTeam

      let scoreWicketsMessage = bt.score;
      10 !== bt.wickets && (scoreWicketsMessage += "-" + bt.wickets)

      let r = bt.overs ? bt.overs : 0
      let s = bt.balls ? bt.balls : 0
      const currentOversBalls = r + "." + s


      return <div className="scorecardTopInfo__eventStats">{bt.name}: {scoreWicketsMessage} ({currentOversBalls} Ovs)</div>
    }

    return null
  }

  const statuses = {
    "InPlay": "In play",
    "BetweenInnings": "Innings Break",
    "Review": "REVIEW",
    "Drinks": "Drinks",
    "Rain": "Rain",
    "BadLight": "Bad light stopped play",
    "Injury": "Injury timeout",
    "Other": "Delay",
  }

  const tossWinnerData = () => {
    const e = sportEventStats.stats
    let a = e.status,
      i = {
        message: "",
        statusMessage: "",
        statusStyle: "Review" === a ? "review" : ""
      }

    if ("BetweenInnings" === a || 2 === e.currentInning) {
      if ("Completed" !== a) {
        if (null !== e.battingTeam.requiredRuns || e.battingTeam.target) {
          let r = {
            team: e.battingTeam?.name,
            requiredScore: null !== e.battingTeam?.requiredRuns ? e.battingTeam?.requiredRuns : e.battingTeam?.target
          }
          /*i.message = (e.battingTeam.name + ' need ' + r.requiredScore + ' runs',
            (null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")))*/
          i.message = e.battingTeam?.name + ' need ' + r.requiredScore + ' runs'
          null !== e.battingTeam?.requiredRuns && e.battingTeam?.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")
        }
      } else e.result && "Won" === e.result.type && (i.message = 'Match won by ' + e.result.winnerName);
    } else e.tossWinner && e.tossWinner?.name && e.tossWinner?.decision && (i.message = e.tossWinner?.name + " " + (e.tossWinner.decision === 'optToBowl' ? "opt to bowl" : "opt to bat"))

    return (statuses.hasOwnProperty(a) && (i.statusMessage = statuses[a] + (i.message.length ? " - " : "")),
    e.adjustedOvers && "Completed" !== a && i.message.length && (i.message += ' - reduced to ' + (e.oversPerInning || 0) + ' overs'),
      i)
  }

  const tossWinner = () => {
    if (sportEventStats?.stats?.tossWinner?.name) {
      const data = tossWinnerData()
      // return <div className="EventHeader__details__additional">{sportStats.stats.tossWinner.name} {sportStats.stats.tossWinner.decision}</div>

      return <div className="scorecardTopInfo__tossWinner"><span className={`${data.statusStyle}`}>{data.statusMessage}</span> {data.message}</div>
    }
  }
  //// end score logic

  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader className="onscrollHeader" style={style}>
            <Toolbar
              contentScrolled={contentScrolled}
              tabs={isLoaded ? (isRacing ? raceTabs : sportTabs) : false}
              activeTab={activeTab}
              eventHeader={isLoaded}
              setStyle={setStyle}
            />
          </IonHeader>

          <IonContent
            className="EventPage"
            fullscreen
            onIonScroll={onScroll}
            scrollEvents={true}
          >
            {activeTab === 2 && isLoaded ? (
              <>
                <LiveSection
                  eventTypeId={eventTypeId}
                  eventId={eventId}
                  historyVideo={history.location.pathname}
                />
              </>
            ) : null}
            {(activeTab === 0 || activeTab === 2) && renderPageContent()}
            {activeTab === 1 && (
              <div className="news">
                {twitterSettings && (
                  <Timeline
                    dataSource={{
                      sourceType: twitterSettings['4'].sourceType,
                      slug: twitterSettings['4'].slug,
                      ownerScreenName: twitterSettings['4'].ownerScreenName,
                    }}
                    options={{
                      ...twitterSettings['4'].options,
                      tweetLimit: false,
                    }}
                  />
                )}
              </div>
            )}
            {activeTab === 3 && <OpenBets openBets={openBets} isEvent={true} />}
          </IonContent>
        </IonPage>
      ) : (
        <div
          className="EventPage"
          fullscreen
          onIonScroll={onScroll}
          scrollEvents={true}
        >
            {/*<div style={{ marginBottom: '15px' }} className="favourites-header">*/}
            {/* {(racingEventHeader && !sportData && !sportData[0]?.event?.name) ? null : ""} */}
          
          {event && (event?.match_name || event?.name) && 
          // !isRacing &&
            <div
            style={{ marginBottom: '15px' }}
            className={`favourites-header   ${
              hasVideo ? 'withPlay' : ''
            }`}
          >
              <div className={`favourites-header__inner-wrapper`}>
                {hasVideo && (
                  <IonImg
                    className="inplay-item__broadcast-icon"
                    src={broadcastImg}
                  />
                  )}
                  {/* {racingEventHeader && null} */}
                <h1 style={{ marginLeft: '12px' }}>
                  {event?.match_name || event?.name} 
                </h1>
                {event && (event?.match_date || event?.market_start_time) && (
                  <div className="sportsPage-headerBet">
                    {optimizedDate && optimizedDate.includes("NaN") ? (
                      <span>
                        {event?.match_date}
                      </span>
                    ) :(
                    <>
                      <span>{optimizedDate.slice(0, 10)}</span>&nbsp;
                      <span>
                        {optimizedDate.slice(11, 20).split(':', 2).join(':')}
                      </span>
                    </>
                    )}
                  </div>
                )}
              </div>
              { sportEventStats && ((sportEventStats.stats && sportEventStats?.scorecard && sportEventStats?.stats?.battingTeam)  ||
                (sportEventStats.stats && sportEventStats?.scorecard && sportEventStats?.stats?.tossWinner?.name)) &&
                <div className={`favourites-header__scoreCardInfo-content`}>
                  {sportEventStats?.stats?.battingTeam && eventStats()}
                  {sportEventStats?.stats?.tossWinner?.name && tossWinner()}
                </div>
              }
          </div>}
          
          {activeTab === 2 && isLoaded ? (
            <>
              <LiveSection
                eventTypeId={eventTypeId}
                eventId={eventId}
                historyVideo={history.location.pathname}
              />
            </>
          ) : null}
          {(activeTab === 0 || activeTab === 2) && renderPageContent()}
          {activeTab === 1 && (
            <div className="news">
              {twitterSettings && (
                <Timeline
                  dataSource={{
                    sourceType: twitterSettings['4'].sourceType,
                    slug: twitterSettings['4'].slug,
                    ownerScreenName: twitterSettings['4'].ownerScreenName,
                  }}
                  options={{
                    ...twitterSettings['4'].options,
                    tweetLimit: false,
                  }}
                />
              )}
            </div>
          )}
          {activeTab === 3 && <OpenBets openBets={openBets} isEvent={true} />}
        </div>
      )}
    </>
  )
}

// export default EventPage;
export default connectEventPage()(EventPage)
