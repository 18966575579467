import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
} from '@ionic/react'
import './Competitions.scss'
import { connectCompetition } from './connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useParams } from 'react-router'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import { getMonthName, getFormattedDate } from '../../helpers/date.helper'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const Competitions = ({
  menu,
  competition,
  timeSettings,
  elementsParams,
  actions,
  ...props
}) => {
  const { eventTypeId, competitionId, series_name } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname.indexOf('competition') !== -1) {
      if (!competition || !competition[0]) {
        actions
          .requestCompetition(competitionId)
          .catch((e) => console.log('requestCompetition error = ', e))
      } else {
        if (competition[0].id !== competitionId) {
          actions
            .requestCompetition(competitionId)
            .catch((e) => console.log('requestCompetition error = ', e))
        }
      }
    }
  }, [eventTypeId, competitionId])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

      //TODO: call another API to show children
  const [event, setEvent] = useState("1") 

  const prepareCompetitions = () => {
    const labelDate = (date) => {
      const formattedOpenDate = getFormattedDate(
        date,
        timeSettings.timeZoneName
      )
      return `${formattedOpenDate.date} ${formattedOpenDate.time}`
    }

    const headerDate = (date) => {
      const day = date.getDate()
      const month = getMonthName(date.getMonth())
      const year = date.getFullYear()
      return `${day}th ${month} ${year}`
    }

    if (competition) {
      if (competition.length) {
        //group by time of competition.event.openDate
        const result = {}
        // setEvent(competition[0].series_name)
        competition.forEach((item) => {
          const itemDate = new Date(item.match_date).toDateString()
          if (!result[itemDate]) {
            result[itemDate] = {
              date: new Date(item.match_date),
              events: [],
            }
          }
          result[itemDate].events.push(item)
        })

        const now = new Date()
        return Object.entries(result)
          .sort((a, b) => a[1].date - b[1].date)
          .map(([key, group]) => (
            <IonItemGroup className="Competitions__list__group" key={key}>
              <IonItemDivider className="Competitions__list__group__divider">
                <IonLabel>{headerDate(group.date)}</IonLabel>
              </IonItemDivider>
              {group.events.map((item) => {
                const itemDate = new Date(item.match_date)
                return (
                  <IonItem
                    key={item.id}
                    className="Competitions__list__group__item"
                    onClick={() =>{
                      localStorage.setItem("Event", JSON.stringify(item));
                      history.push(
                        `/event-page/${item.eventTypeId}/${item.match_id}`
                      )
                    }}
                  >
                    <div className="Competitions__list__group__item__label">
                      <div className="Competitions__list__group__item__label__name">
                        {item.match_name}
                      </div>
                      <div className="Competitions__list__group__item__label__time">
                        {itemDate > now && labelDate(itemDate)}
                      </div>
                    </div>
                    {itemDate <= now && (
                      <span className="Competitions__list__group__item__inplay">
                        ꀆ
                      </span>
                    )}
                  </IonItem>
                )
              })}
            </IonItemGroup>
          ))
      }
    }
    return (
      <div className="Competitions__noEvent">No competitions available</div>
    )
  }
  console.log("competitions in component", competition)

  return (
    <IonPage>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: '135px' }}></div>
      )}
      {width < 1024 ? (
        <IonContent className="Competitions" fullscreen>
          {competition ? (
            <IonList className="Competitions__list">
              <IonListHeader className="Competitions__list__header">
                {competition && competition[0] && competition[0]?.series_name}
              </IonListHeader>
              {prepareCompetitions()}
            </IonList>
          ) : (
            <LoaderContent />
          )}
        </IonContent>
      ) : (
        <>
          <div className="Competitions">
            {competition ? (
              <>
                <IonList className="Competitions__list">
                  <IonListHeader className="Competitions__list__header">
                    {event && event.name}
                  </IonListHeader>
                  {prepareCompetitions()}
                </IonList>
              </>
            ) : (
              <LoaderContent />
            )}
          </div>
          {/*<FooterDesktop />*/}
        </>
      )}


    </IonPage>
  )
}

export default connectCompetition()(Competitions)
