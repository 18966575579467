import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks'
import { requestPromotions } from '../../store/promotions/promotions.thunks'
import { getInplayPopularSuccess } from '../../store/inplayPopular/inplayPopular.slice'
import { getPromotionsSuccess } from '../../store/promotions/promotions.slice'
import { requestOrderCount } from '../../store/orderCount/orderCount.thunks'
import { setLegalAgePopoverShow } from '../../store/modals/legalAgePopover.slice'
import { setKycPopoverShow } from '../../store/modals/kycPopover.slice'
import { setMoreRulesPopoverShow } from '../../store/modals/moreRulesPopover.slice'

import { requestSportPageEvent, requestSportPageEventDetailed} from '../../store/sportPageEvent/sportPageEvent.thunks'
import { getSportPageEventSuccess, getSportPageEventDetailedSuccess } from '../../store/sportPageEvent/sportPageEvent.slice'

import { setIsMoreDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsAltName, setRenderIonContent } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { requestLiveCasinoID } from '../../store/liveCasino/liveCasino.thunks'

export const connectSportsPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      lastActivityDate: state.balance.lastActivityDate,
      inplayPopular: state.inplayPopular,
      promotions: state.promotions,
      legalAgePopoverShow: state.legalAgePopoverShow,
      kycPopoverShow: state.kycPopoverShow,
      moreRulesPopoverShow: state.moreRulesPopoverShow,
      balanceSettings: state.balanceSettings,
      betPlacing: state.betPlacing,
      orderCount: state.orderCount,
      timeSettings: state.timeSettings,
      sportPageEvent: state.sportPageEvent,
      altName: state.navigationHeaderMenu.altName,
      renderIonContent: state.navigationHeaderMenu.renderIonContent,
      isNavigationOpen: state.navigationHeaderMenu.isOpen,
      auth: state.auth,
      navigationID: state.navigationHeaderMenu.navigationID,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestOrderCount,
      requestInplayPopular,
      requestPromotions,
      requestSportPageEvent,
      requestSportPageEventDetailed,

      getSportPageEventSuccess,
      getSportPageEventDetailedSuccess,
      setLegalAgePopoverShow,
      setKycPopoverShow,
      setMoreRulesPopoverShow,
      getInplayPopularSuccess,
      getPromotionsSuccess,
      setIsMoreDetailed,
      setIsAltName,
      setRenderIonContent,
      requestLiveCasinoID,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
