import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setMarket,
  setRunner,
  setIsBack,
  setPrice,
  setPriceActual,
  setStake,
  setDelay,
  setMtype,
  setBtype,
  setLine,
  setRunnerName,
  setEventName,
  setShowBetPlacingPopover,
  setBetPlacingPopoverStage,
  setFromOneClick,
  setStartTime,
  setEventTypeId,
  setMaxBet,
  setMinBet,
  setMinMaxAll
} from '../../store/betPlacing/betPlacing.slice'
import { requestBetPlacingStart } from '../../store/betPlacing/betPlacing.thunks'

export const connectOddbutton = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      betPlacing: state.betPlacing,
      oneClick: state.user.settings.oneClickSettings,
      balanceSettings: state.balanceSettings,
      sportLimits: state.sportEvent.sportLimits,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setMarket,
      setRunner,
      setRunnerName,
      setEventName,
      setIsBack,
      setPrice,
      setPriceActual,
      setDelay,
      setStake,
      setMtype,
      setBtype,
      setLine,
      setShowBetPlacingPopover,
      setBetPlacingPopoverStage,
      setFromOneClick,
      requestBetPlacingStart,
      setStartTime,
      setEventTypeId,
      setMaxBet,
      setMinBet,
      setMinMaxAll
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
