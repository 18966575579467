import React, { useEffect, useRef, useState } from 'react';
import './CasinoProvider.scss';
import HeaderTabs from './ProviderTabs/HeaderTabs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { connectCasinoProvider } from "./CasinoProvider.connect";
import { setLoginNew } from '../../store/LoginNew/loginNew.slice';
import { withIonLifeCycle } from '@ionic/react';
import SlotsCasinoPopUp from '../../components/SlotsCasinoPopup';


const CasinoProvider = ({ qtechFilter, qTechGames, auth, slotCasinoPopUp, actions, elementsParams }) => {
    const [selectedIndex, setSelectedIndex] = useState('EZU');
    const [loading, setLoading] = useState(false);
    const casinoRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const getQTechFilter = () => {
        actions
            .requestQtechFilter()
            .catch((e) => console.log('requestQtechFilter error = ', e))
    }

    const getQTechGames = (provider) => {
        setLoading(true)
        actions
            .requestQtechGames(provider)
            .then(() => setLoading(false))
            .catch((e) => console.log('requestQtechGames error = ', e))
    }

    useEffect(() => {
        getQTechFilter();
        getQTechGames('EZU');
    }, []);

    const onSelectFilter = (data) => {
        setSelectedIndex(data);
        getQTechGames(data);
    }


    const onSelectGame = (data) => {
        if (!data?.id) return;
        const id = data?.id;
        const tableId = data?.provider?.id || '';

        if (auth) {
            let data = { gameId: id, providerCode: tableId}
            actions.requestQTechCasinoID(data)
            history.push(`/live_casino_slot/${id}`)
            // dispatch(openSlotCasinoPopUp());
        } else {
            dispatch(setLoginNew(true));
            // dispatch(setLoginNavigationData({ page: 'casino_live', ids: [id, tableId] }));
        }
    }

    function getPreferredImage(images) {
        // Initialize variables for banner and logo-square URLs
        let bannerImage = null;
        let logoSquareImage = null;

        // Loop through the array of images
        images.forEach(image => {
            if (image.type === "banner") {
                bannerImage = image.url;
            } else if (image.type === "logo-square") {
                logoSquareImage = image.url;
            }
        });

        // Return the banner image if available, otherwise return the logo-square image
        return bannerImage || logoSquareImage;
    }

    return (
        <>
        {slotCasinoPopUp && <SlotsCasinoPopUp/>}
        
        <div ref={casinoRef} className='casino-provider-container'>
            <div className='casino-provider-header-list'>
                <HeaderTabs
                    tabs={qtechFilter}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={(val) => { onSelectFilter(val); }}
                />
            </div>

            {
                <div className='casino-games-container'>
                    {(qTechGames?.length > 0) &&
                        qTechGames.map((item) => {
                            return (
                                <div
                                    className='casino-game-box' key={item?.id}
                                    onClick={() => { onSelectGame(item); }}
                                >
                                    <div className='casino-icon-box'>
                                        <img
                                            src={getPreferredImage(item?.images)}
                                            alt={item?.name}
                                        />
                                        <div className='casino-name'>PLAY NOW</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }

            {loading &&
                <div className='spinner-container'>
                    <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px' }}>
                        <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            }

        </div>
        </>
    )
}

export default withIonLifeCycle(connectCasinoProvider()(CasinoProvider));