import './CancelBonusPopUp.scss'
import React from 'react'
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const CancelBonusPopUp = ({ onDidDismiss }) => {

  return createPortal(
    <>
        <div className="cancelBonusPopUp">
          <div className="cancelBonusPopUp-main">
            <div className='cancelBonusPopUp-main_header'>

            <div className='cancelBonusPopUp-main_header_title'>Cancel Active Bonus</div>
            </div>
         
            <div onClick={() => onDidDismiss()} className="cancelBonusPopUp-main-close"></div>
            <div className="cancelBonusPopUp-main-content">
              <div className="cancelBonusPopUp-main-content-text">
              Are you sure you want to cancel this active bonus?
              </div>
              <div className="cancelBonusPopUp-main-content-buttonsRow">
              <div className="cancelBonusPopUp-main-content-buttonsRow-btn greenBtn" onClick={() => onDidDismiss()}>No Continue</div>
              <div className="cancelBonusPopUp-main-content-buttonsRow-btn" onClick={() => onDidDismiss()}>Yes Cancel</div>


              </div>

            </div>
          </div>
        </div>
    </>, modalRoot
    )
}

export default CancelBonusPopUp
