import "./BettingProfitAndLossDetailsTable.scss";
import React from "react";
import { tsToDate } from "../../helpers/date.helper";
import RoundResults from '../RoundResults/RoundResults'
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const BettingProfitAndLossDetailsTable = ({ itemDetails, selectedItemDetails, timeSettings, setSelectedItemDetails, breadcrumbNames }) => {

  const roundId = selectedItemDetails && selectedItemDetails?.match_id;

  const getBackSubtotal = () => {
    if (itemDetails.length <= 0) return 0;

    let sum = 0;
    itemDetails.forEach(item => {
      if (item.is_back === 1) {
        sum += (item?.chips || 0);
      }
    });
    return sum;
  };

  const getLaySubtotal = () => {
    if (itemDetails.length <= 0) return 0;

    let sum = 0;
    itemDetails.forEach(item => {
      if (item.is_back === 0) {
        sum += (item?.chips || 0);
      }
    });
    return sum;
  };

  const getNetMarketTotal = () => {
    if (itemDetails.length <= 0) return 0;

    return (getBackSubtotal() + getLaySubtotal()) - (selectedItemDetails?.memberComm || 0);
  };

  return (
    <div className="bettingProfitAndLossDetailsTable">
      <p className="bettingProfitAndLossDetailsTable__table-breadcrumbs">
        <span onClick={() => { setSelectedItemDetails(null); }} className="text-bold">{itemDetails && itemDetails[0]?.sport_name}</span>
        <span> `{'>'}` {itemDetails && itemDetails[0]?.match_name} - {itemDetails[0]?.market_name}</span>
      </p>
      <div className="bettingProfitAndLossDetailsTable__table-inner">
        <ul className="bettingProfitAndLossDetailsTable__table-header">
          <li>Placed</li>
          <li>Selection</li>
          <li>Bet ID</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li className="info-right">Profit/Loss</li>
          <li className="info-right">Status</li>
        </ul>
        <div className="bettingProfitAndLossDetailsTable__table-body">
          {itemDetails.length >= 1 && itemDetails.map((item, index) =>
            <ul className="bettingProfitAndLossDetailsTable__table-body-row" key={item?.lastSettledDate + index}>
              <li>
                <span className="text-bold">{tsToDate(item?.createdAt, timeSettings?.timeZoneName)?.split(" ")[0].split("/").map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem).join("/")}
                </span>
                <span className="text-color">| {tsToDate(item?.createdAt, timeSettings?.timeZoneName).split(" ")[1]}</span>
              </li>
              <li>{item?.selection_name}</li>
              <li>{item?.bet_id}</li>
              <li>{item?.is_back === 1 ? "Back" : "Lay"}</li>
              <li>{item?.odds.toFixed(3)}</li>
              <li>{getCurrencyFormatWithZeroAfterDot(item?.stack)}</li>
              <li className={`info-right text-bold ${item?.chips >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.chips)}</li>
              <li className={`info-right text-bold ${item?.chips >= 0 ? "green" : "red"}`}>{item?.chips >= 0 ? "WON" : "LOSS"}</li>
            </ul>,
          )}
        </div>
      </div>
      <div className="bettingProfitAndLossDetailsTable__info-wrapper">
        <ul className="bettingProfitAndLossDetailsTable__info">
          <li>
            <span className="bettingProfitAndLossDetailsTable__info-title">Back subtotal:</span>
            <span className={`bettingProfitAndLossDetailsTable__info-price ${getBackSubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
              {getCurrencyFormatWithZeroAfterDot(getBackSubtotal())}
            </span>
          </li>
          <li>
            <span className="bettingProfitAndLossDetailsTable__info-title">Lay subtotal</span>
            <span className={`bettingProfitAndLossDetailsTable__info-price ${getLaySubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
              {getCurrencyFormatWithZeroAfterDot(getLaySubtotal())}
            </span>
          </li>
          <li>
            <span className="bettingProfitAndLossDetailsTable__info-title">Market subtotal:</span>
            <span className={`bettingProfitAndLossDetailsTable__info-price ${((getBackSubtotal() + getLaySubtotal()).toFixed(2)) < 0 ? "negative-value" : ""}`}>
              {getCurrencyFormatWithZeroAfterDot(getBackSubtotal() + getLaySubtotal())}
            </span>
          </li>
          <li>
            <span className="bettingProfitAndLossDetailsTable__info-title">Commission:</span>
            <span className={`bettingProfitAndLossDetailsTable__info-price`}>
              {getCurrencyFormatWithZeroAfterDot(selectedItemDetails?.memberComm) || 0}
            </span>
          </li>
          <li>
            <span className="bettingProfitAndLossDetailsTable__info-title">Net Market Total:</span>
            <span className={`bettingProfitAndLossDetailsTable__info-price bettingProfitAndLossDetailsTable__info-price-with-border ${getNetMarketTotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
              {getCurrencyFormatWithZeroAfterDot(getNetMarketTotal())}
            </span>
          </li>
        </ul>
        {selectedItemDetails?.match_id === "1444001" && <RoundResults roundId={roundId} />}
      </div>
    </div>
  );
};

export default BettingProfitAndLossDetailsTable;
