import './BettingProfitAndLossItemDetails.scss'
import React, { useEffect, useState } from 'react';
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper';

const BettingProfitAndLossItemDetails = ({ itemDetails }) => {
    const [selection, setSelection] = useState('');
    const [betId, setBetId] = useState('');
    const [placed, setPlaced] = useState('');
    const [status, setStatus] = useState('');
    const [profitLoss, setProfitLoss] = useState('');
    const [type, setType] = useState('');
    const [odds, setOdds] = useState('');
    const [stake, setStake] = useState('');

    useEffect(() => {
        parseItemDetails(itemDetails);
    }, [itemDetails])

    const getValueColor = (value) => {
        return value === 0 ? 'pnl-item-value--black' : value > 0 ? 'pnl-item-value--green' : 'pnl-item-value--red';
    }

    const getStatusColor = (status) => {
        return (status === 'WON' || status === 'WIN') ? 'pnl-item-value--green' : 'pnl-item-value--red';
    }

    const getDisplayedTypeValue = () => {
        if (itemDetails.marketBettingType === 'LINE') {
            return `${itemDetails.side ? 'No' : 'Yes'}`;
        }

        return `${itemDetails?.is_back === 0 ? 'Lay' : 'Back'}`;
    }

    const getPriseFor_SB = (marketId, price) => {
        return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((price - 1) * 100).toFixed(2).replace('.00', '') : price);
    }

    const parseItemDetails = (itemDetails) => {
        if (!itemDetails)
            return

            setSelection(itemDetails.selection_name)
            setBetId(itemDetails.bet_id)
            setPlaced(new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'medium' }).format(new Date(itemDetails.createdAt)))
            setStatus(itemDetails.chips >= 0 ? "WON" : "LOSS")
            setProfitLoss(itemDetails?.chips?.toFixed(2))
            setType(getDisplayedTypeValue())

            const averagePrice = getPriseFor_SB(itemDetails.market_id, itemDetails.odds)
            setOdds(averagePrice)
            setStake(itemDetails.stack)

            return;
    }

    return (
      <div className="betting-profit-and-loss-item">
          <div className="betting-profit-and-loss-item__content">
              <div className="betting-profit-and-loss-item__content-top">
                  <div className="betting-profit-and-loss-item__content-top-left">
                      {itemDetails.providerId !== 1 &&
                      <div className="game-type">
                                <span className="game-type-title">
                                    Game Type:
                                </span>
                      </div>
                      }

                      { itemDetails.market_name === 'INNING_RUNS' &&

                      <div className={`selection ${type === 'Yes' ? 'back' : 'lay'}`}>
                          <span className="selection-title">Selection: </span>
                          <span className="selection-value">{itemDetails.line + ' Runs'}</span>
                      </div>

                      }
                      { itemDetails.market_name !== 'INNING_RUNS' &&

                      <div className={`selection ${type === 'Back' ? 'back' : 'lay'}`}>
                          <span className="selection-title">Selection: </span>
                          <span className="selection-value">{selection}</span>
                      </div>

                      }

                      {/*<div className={`selection ${itemDetails.marketBettingType === 'LINE' ? (type == 'Yes' ? 'back' : 'lay') :  (type == 'Back' ? 'back' : 'lay') }`}>*/}
                      {/*    <span className="selection-title">Selection: </span>*/}
                      {/*    <span className="selection-value">{selection}</span>*/}
                      {/*</div>*/}
                      <div className="betId">
                          <span className="betId-title">Bet ID: </span>
                          <span className="betId-value">{betId}</span>
                      </div>
                      <div className="placed">
                          <span className="placed-title">Placed: </span>
                          <span className="placed-value">{placed}</span>
                      </div>
                  </div>
                  <div className="betting-profit-and-loss-item__content-top-right">
                      <div className="status">
                          <span className="status-title">Status:</span>
                          <span className={`status-value ${getStatusColor(status)}`}>{status}</span>
                      </div>
                      {itemDetails.providerId !== 1 &&
                      <div className="stake">
                          <span className="stake-title">Stake:</span>
                          <span className="stake-value">{stake}</span>
                      </div>
                      }
                      <div className="profit-loss">
                          <span className="profit-loss-title">Profit/Loss:</span>
                          <span className={`profit-loss-value ${getValueColor(profitLoss)}`}>{getCurrencyFormat(+profitLoss)}</span>
                      </div>
                  </div>
              </div>
              {
                  itemDetails.providerId === 1 &&
                  <div className="betting-profit-and-loss-item__content-bottom">
                      <div className="type betting-profit-and-loss-item__content-bottom__column">
                          <span className="type-title">TYPE</span>
                          <span className="type-value">{type}</span>
                      </div>
                      <div className="odds betting-profit-and-loss-item__content-bottom__column">
                          <span className="odds-title">ODDS</span>
                          <span className="odds-value">{odds}</span>
                      </div>
                      <div className="stake betting-profit-and-loss-item__content-bottom__column">
                          <span className="stake-title">STAKE</span>
                          <span className="stake-value">{stake}</span>
                      </div>
                  </div>
              }
          </div>
      </div>
    )
}

export default BettingProfitAndLossItemDetails
